import styled from "styled-components";

export const Div = styled.div`
  padding: 8px;
  margin-right: 15px;
  background: #E76F51;
  color: #FFF;
  border-bottom-right-radius: 5px;
  font-weight: 600;
`;
 export const Select = styled.select`
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px;
    outline: none;
    padding-right: 10px
 `
 export const Label = styled.label`
  font-weight: 500;
`;
 export const Input = styled.input`
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 40px;
    outline: none;
 `
 export const ImgSearchInput = `
 data:image/svg+xml,
 %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E
   %3Cpath d='M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 
   0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%23aaa' /%3E
 %3C/svg%3E
`;
 export const SearchInput = styled.input`
  font-size: 16px;
  padding: 18px 12px 18px 40px; 
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 100%;
  height: 20px;
  background-image: url("${ImgSearchInput}");
  background-color: #E0E0E0;
  background-repeat: no-repeat;
  background-position: 10px 50%; 
  background-size: 20px 20px;
  outline: none;

  ::placeholder {
    color: gray;
  }
  
  @media (max-width: 882px) { 
    width: 150px;
  }
  @media (max-width: 436px) { 
    width: 50px;
  }
  
`;

export const DivDotted = styled.div`
  border-bottom: ${ (props) => props.border };
  border-radius: ${ (props) => props.radius };
  color: #000;
  font-weight: 500;
`;

export const DivProfil = styled.div`
width: 35px;
height: 35px;
background: #ccc;
border-radius:50%;
`
export const DivReseau = styled.div`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
font-size: 18px;
border-radius: 50%;
background: #ccc;
border-radius:50%;
color: ${ (props) => props.color };
background: ${ (props) => props.background };
// border: ${ (props) => props.border };
`