import { createSlice } from "@reduxjs/toolkit";

const userSliceWithTokenValidation = createSlice({
    name: "auth",
    initialState: {
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        user_id: null,
        first_name: null,
        last_name: null,
        voyageur: null,
        reservation: null,
    },
    reducers: {
        setAccessToken: (state, action) => {
            return {
                ...state,
                accessToken: action.payload,
                isAuthenticated: true
            }
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setCredentials: (state, action) => {
            return {
                ...state,
                refreshToken: action.payload.refresh_token,
                accessToken: action.payload.access_token,
                isAuthenticated: true
            }
        },
        setUser: (state, action) => {
            return {
                ...state,
                user_id: action.payload,
            };
        },
        setFirstName: (state, action) => {
            return {
                ...state,
                first_name: action.payload,
            };
        },
        setLastName: (state, action) => {
            return {
                ...state,
                last_name: action.payload,
            };
        },
        setSkeep(state) {
            state.isSkeep = true;
        },
        logout: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.user_id = null;
            state.first_name = null;
            state.last_name = null;
        },
        addNewVoyageur: (state, action) => {
            return {
                ...state,
                voyageur: action.payload,
            }
        },
        addNewReservation: (state, action) => {
            console.log(action);
            return {
                ...state,
                reservation: action.payload,
            }
        },
    },
});

export const getUserStatus = (state) => state.auth.isAuthenticated;
export const getSkeep = (state) => state?.dataPersisted?.user?.isSkeep;
export const selectToken = (state) => state.auth.token;
export const selectFirstName = (state) => state.auth.first_name;
export const selectLastName = (state) => state.auth.last_name;

export const { addNewReservation, addNewVoyageur, setCredentials, setAccessToken, setUser, setRefreshToken, logout, setSkeep, setFirstName, setLastName } =
    userSliceWithTokenValidation.actions;

export default userSliceWithTokenValidation.reducer;