import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout, setAccessToken, selectToken } from "redux/features/userSliceWithTokenValidation";
import api from "routes/api/global-vars";
import { authentificate } from "routes/api/endpoints";

export const API = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: api,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState());
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: authentificate.onLogin(),
        method: "POST",
        body: { email, password },
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: authentificate.onLogout(),
        method: "POST",
      }),
      onSuccess: (_, { dispatch }) => {
        dispatch(logout());
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: authentificate.onRefreshToken(),
        method: "POST",
      }),
      onSuccess: (response, { dispatch }) => {
        const { access } = response.data;
        dispatch(setAccessToken(access));
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: authentificate.onForgotPassword(),
        method: "POST",
        body: { email },
      }),
      onSuccess: (response, { dispatch }) => {
        const message = response.data.message;
        if (message) {
          dispatch(showSuccessMessage(message));
        } else {
          dispatch(showSuccessMessage("Instructions de réinitialisation envoyées avec succès."));
        }
      },
    }),
    resetPassword: builder.mutation({
      query: ({ code, email, new_password_confirm, new_password }) => ({
        url: authentificate.onResetPassword(),
        method: "POST",
        body: { code, email, new_password_confirm, new_password },
      }),
    }),
  })
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useRefreshTokenMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = API;

export default API