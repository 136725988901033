import React, { useMemo } from "react"
import { useTable } from "react-table"
import { PoductService } from "./ProductService"
import DATA from "./DATA.json"

const CadreTableau = ({ showTableBody, tableauBody }) => {
    const columns = useMemo(() => PoductService, [])
    const data = useMemo(() => DATA, [])

    const tableInstance = useTable({
        columns,
        data
    })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    return (
        <table className="table" {...getTableProps()}>
            <thead className="table-light">
                {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                    <th key={index} {...column.getHeaderProps()}>
                        {column.render("Header")}
                    </th>
                    ))}
                </tr>
                ))}
            </thead>
            {showTableBody && (
            <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
                prepareRow(row);
                return (
                <tr key={index} {...row.getRowProps()}>
                    {row.cells.map((cell, index) => (
                    <td key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                    </td>
                    ))}
                </tr>
                );
            })}
            </tbody>
            )}
        </table>

    )
}

export default CadreTableau