import React from "react";
import file from "../../../image/Group 101998.png"

function LinkComponent() {
  return (
    <div className="col">
      <img src={file} alt="img" />
    </div>
  )
}

export default LinkComponent