import React, { useState } from "react";
import ATraiterCard from "./ATraiterCard";
import ConversationAtraiter from "./ConversationAtraiter";

function ATraiter() {
  const [showConversation, setShowConversation] = useState(false);
  const handleClick = () => {
    setShowConversation(!showConversation);
  }
  return (
    <div>
      <div>
        {!showConversation && <ATraiterCard func={handleClick} />}
        {showConversation && <ConversationAtraiter />}
      </div>
    </div>
  )
}

export default ATraiter