import React from "react";
import { SidebarItems } from "pages/Admin/StyledAdmin";

function SidebarItem({ icon, title, positionIcon, path, id }) {
  return (
    <SidebarItems className="d-flex py-3 mb-2 mt-2" to={path} id={id}>
        <div className="col-md-9 d-flex align-items-center gap-3 ps-3">
            <span>{icon}</span>
            <span>{title}</span>
        </div>
        <div className="col-md-3 d-flex justify-content-end">
            <span>{positionIcon}</span>
        </div>
    </SidebarItems>
  )
}

export default SidebarItem