import React from "react";
// import vector from "./../../../../image/Vector.png";
// import cloche from "./../../../../image/cloche.png";
import BasicTable from "components/Tableau/BasicTable";
import CustomButton from "components/Boutton/CustomButton";
import { HiOutlineShoppingCart } from "react-icons/hi";

const ServiceCommande = () => {
  return (
    <div className="row card d-flex flex-row justify-content-center" style={{ border: "none" }}>
      <div className="card-header d-flex flex-wrap" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="d-flex align-items-center justify-content-center" style={{ color: "black" }}>
          <HiOutlineShoppingCart /> Services commandés
        </div>
        <div>
          <CustomButton className="btn "
            backgroundColor="#FFC857"
            color="black"
            border="none"
            margin='0 10px 0 10px'
            padding="10px 20px"
            fontSize="14px">Accessoires</CustomButton>
        </div>
        <div>
          <CustomButton
            className="btn"
            color="black"
            padding="10px 20px"
            margin='0 10px 0 0'
            background="transparent"
            border="1px solid #FFC857"
            fontSize="14px"
          >Conception</CustomButton>
        </div>
        <div>
          <CustomButton
            className="btn"
            color="black"
            padding="10px 20px"
            margin='0 10px 0 0'
            background="transparent"
            border="1px solid #FFC857"
            fontSize="14px"
          >Option</CustomButton>
        </div>
        <div>
          <CustomButton
            className="btn"
            color="black"
            padding="10px 20px"
            margin='0 10px 0 0'
            background="transparent"
            border="1px solid #FFC857"
            fontSize="14px"
          >Journal</CustomButton>
        </div>
      </div>
      <div className="card-body" style={{ margin: "auto", width: "100%" }}>
        <BasicTable />
        {/* <img src={cloche} alt="" /> */}
      </div>
    </div>
  );
};

export default ServiceCommande;
