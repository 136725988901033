import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import CardInfoClient from "./CardInfoClient"
import PhotoProfile from "./PhotoProfile"
import InfoPersonnel from "./InfoPersonnel"
import ListeTaches from "./ListeTaches"
import Checkbox from "./Checkbox"
import { Boutton, Heading2, Heading4, Heading5 } from "./InfoClientCSS"
import { MdMailOutline } from "react-icons/md";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import DrapeauSenegal from "../../../image/DrapeauSenegal.jpg"

export default function CardLeft() {
  const taches = [
    { date: "14 Mars 2024", title: "Ne prend pas de sucre dans son café" },
    { date: "13 Mars 2024", title: "Il est dans la chambre 245" },
  ]
  const options = [
    { value: "allowed", label: "Autorisé" },
    { value: "notAllowed", label: "Non autorisé" },
  ]
  return (
    <CardInfoClient>
      <div className="card-body p-3 d-flex flex-column justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <PhotoProfile width="80px" height="80px" fontSize="44px" />
          <div className="nom text-center mt-3">
            <Heading2>M.Remy</Heading2>
            <Heading2>Mendy</Heading2>
          </div>
          <div>
          <Boutton className="px-3 pt-1">
            <Heading5><MdMailOutline /> Email</Heading5>
          </Boutton>
          </div>

          <div className="nom text-center mt-4">
            <InfoPersonnel ms="ms-3" label="Age :" value="29ans (04/04/1995)" />
            <InfoPersonnel ms="ms-3" label="Langue :" value="Française" />
            <InfoPersonnel ms="ms-3" label="Pays :" img={DrapeauSenegal} value="Sénégal" />
          </div>
        </div>

        <hr />

        <div className="d-flex justify-content-between mb-2">
          <Heading4>Informations sociales</Heading4>
          <HiOutlineSpeakerphone />
        </div>
        <InfoPersonnel ms="ms-3" label="Genre :" value="Homme" />
        <InfoPersonnel ms="ms-3" label="Société :" value="Bakeli School" />
        <InfoPersonnel ms="ms-3" label="Poste :" value="Designer" />
        <InfoPersonnel ms="ms-3" label="Résidence :" value="Ouakam" />

        <hr />

        <Heading4 className="mb-2">Coordonnées</Heading4>
        <InfoPersonnel ms="ms-3" label="Whatsapp :" value="+221 77 777 77 77" />
        <InfoPersonnel ms="ms-3" label="E-mail :" value="Information@gmail.com" />

        <hr />

        <ListeTaches taches={taches} />

        <hr />

        <Heading4>Envoie d'e-mail</Heading4>
        <Checkbox flexDirection="row" justifycontent="justify-content-between" options={options}/>

        <hr />

          <Boutton className="p-2" data-bs-toggle="modal" data-bs-target="#modalInfo">
            <Heading5>Provenance des informations</Heading5>
          </Boutton>
      </div>
    </CardInfoClient>
  )
}
