import React from "react"
import CardContainAdmin from "./cards/CardContainAdmin"
import Voyageur from "../../image/geoupeDePers.png"
import Reservation from "../../image/hEtPers.png"
import Send from "../../image/sending.png"
import Message from "../../image/textMessage.png"
import Reporting from "../../image/stats.png"
import Parametres from "../../image/parametre.png"
import styled from "styled-components"
import { Link } from "react-router-dom"


const ContainerGlobalCard = styled.div`
    background: #FFFFFF;
    border-radius:14px;
    padding: 20px ;
    padding-bottom: 30px ;
`

const ButtonGestion = styled.button`
    width:70px;
    height:30px;
    background: #FFC857;
    border:none;
    color:black;
    font-weight:700;
    border-radius:5px;
    font-size:13px;
`
const AdminContain = () => {
    return (
        <ContainerGlobalCard className="container d-flex flex-wrap justify-content-center align-items-center mt-5">
            <Link to="textes-prepares">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Message} alt="icon" />}
                    title="Textes préparées"
                    description="Gérer les textes préparés afin de les utiliser dans
                    les réponses au Pre-stay, in-stay et post stay"
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
            <Link to="gestion-voyageur">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Voyageur} alt="icon" />}
                    title="Gestion des voyageurs"
                    description="Synchroniser, importer en Excel / CSV ou
                ajouter manuellement des réservations ."
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
            <Link to="gestion-reservation">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Reservation} alt="icon" />}
                    title="Gestion des reservations"
                    description="Gerer les textes préparés afin de les utiliser

                dans les réponses au Pre-stay, in-stay et post stay"
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
            <Link to="#">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Send} alt="icon" />}
                    title="Gestion des envoies"
                    description="Gerer les textes préparés afin de les utiliser
                dans les réponses au Pre-stay, in-stay et post stay"
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
            <Link to="#">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Reporting} alt="icon" />}
                    title="Reporting"
                    description="Gerer les textes préparés afin de les utiliser
                dans les réponses au Pre-stay, in-stay et post stay"
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
            <Link to="#">
                <CardContainAdmin
                    icon={<img className="w-75 h-75" src={Parametres} alt="icon" />}
                    title="Parametres"
                    description="Gerer les textes préparés afin de les utiliser
                dans les réponses au Pre-stay, in-stay et post stay"
                    button={<ButtonGestion>Gestion</ButtonGestion>}
                />
            </Link>
        </ContainerGlobalCard>
    )
}

export default AdminContain
