
import React from "react";
import { Calendar } from "primereact/calendar";
import { Label } from "./NouveauVoyageurCss";

export default function InputCalendrier({ label, date, setDate }) {
    return (
            <>
                <Label className="mb-1">{label}</Label>
                <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon />
            </>
    )
}