import React from "react"
import LinkComponent from "./LinkComponent"

function Liens() {
  return (
    <div className="row m-0">
      <LinkComponent />
      <LinkComponent />
      <LinkComponent />
      <LinkComponent />
    </div>
  )
}

export default Liens