// Input.js
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./Input.css";
// import { ref } from "yup";

const Input = forwardRef(({
  label,
  type,
  id,
  placeholder,
  value,
  name,
  required,
  style,
  min,
  className,
  errorMessage,
  readOnly,
  ...rest
}, ref) => {
    return (
      <div className="mb-3 d-flex flex-column">
        <label
          htmlFor={id}
          className="form-label"
          style={{
            color: "#555",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          {label}
        </label>
        <input
          type={type}
          className={`${className} ps-2`}
          name={name}
          id={id}
          placeholder={placeholder}
          required={required}
          style={style}
          min={min}
          readOnly={readOnly}
          ref={ref}
          {...rest}
        />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    );
  }
)

Input.displayName = "Input";

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  min: PropTypes.number,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default Input;
