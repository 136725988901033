import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PUBLIC_PATH } from "../routes/navigation/navigationPaths";
import useIsAuthenticate from "hooks/useIsAuthenticatedUser";
import ModalInfo from "components/RelationClientDetail/InfoClient/ModalInfo";

/*
|---------------------------------------------------------------
| Verification d'authentification pour acceder aux pages privates
|------------------------------------------------          ---------------
| Seules les routes privates peuvent etre des children de RequireAuth
|
| Vous pouvez changer ici la redirection vers quelle route public aller
| au lieu de rediriger vers "PUBLIC_PATH", au cas ou on est authentifier
*/
function RequireAuth() {
  const isAuthenticated = useIsAuthenticate();
  const optionsModal = [
    { value: "PMS", label: "PMS" },
    { value: "checkin", label: "Check in" },
    { value: "personalisé", label: "Personalisé" },
  ]
  // useEffect(() => (
  // ), [])
  const location = useLocation();

  return isAuthenticated ? (
    <>
      <Outlet />
      <ModalInfo options={optionsModal} />
    </>
  ) : (
    <>
      <ModalInfo options={optionsModal} />
      <Navigate to={PUBLIC_PATH} state={{ from: location }} replace />
    </>
  );
}

export default RequireAuth;
