import React from "react";
import { SearchInput } from "components/HeaderListe/Navbarcss";
import SIZE from "utils/Variables/size";
import { useNavigate } from "react-router-dom";
import { TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";

function HeaderEmailTextPrep() {
    const navigate = useNavigate();
    const switchEmail = () => {
        navigate(TEXTESPREPARES_NEW);
    }
  return (
    <div className="d-flex p-0 py-3 align-items-center">
        <div className="col-md-6">
            <SearchInput placeholder="Rechercher par nom" />
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-center">
            <button className="btn btn-warning" onClick={switchEmail} style={{ fontWeight: "500", fontSize: `${SIZE.large}` }}>Nouveau E-mail</button>
        </div>
    </div>
  )
}

export default HeaderEmailTextPrep