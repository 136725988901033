import React from "react"
import { Heading1 } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import HistoriqueElementTraite from "components/Traiter/HistoriqueElementTraite";

const ElementTraite = () => {
  return (
    <div className="container-fluid py-5" style={styledRelationClientDetail.relationClient}>
      <Heading1
        style={{
          color: "#FFF",
          textDecoration: "underline",
          textAlign: "center",
          paddingTop: "50px",
          fontWeight: "400"
        }}
      >
        Historique des éléments déjà traités
      </Heading1>
      <ContainerDemandeClient className="container mt-5">
        <HistoriqueElementTraite />
      </ContainerDemandeClient>
    </div>
  )
}

export default ElementTraite
