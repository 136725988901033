import { Button } from "components/AdminTextPrepares/NouvelleEmail/NouvelleEmailCss"
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS"
import { Heading1, Heading5 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss"
import React, { useEffect, useState } from "react"
import FormInfoVoyageur from "./FormInfoVoyageur"
import { Div, DivDotted, DivProfil, DivReseau, SearchInput } from "./NouveauVoyageurCss"
import FormInfoResrvation from "./FormInfoReservation"
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom"
import { GESTION_VOYAGEUR_PATH, ADMIN_PATH } from "routes/navigation/navigationPaths";
import { useDispatch, useSelector } from "react-redux";
import { useAddNewVoyageurMutation, useGetVoyageursQuery } from "services/Voyageurservices"
import { addNewReservation, addNewVoyageur } from "redux/features/userSliceWithTokenValidation"
import { useAddNewReservationMutation, useGetRoomsQuery } from "services/ReservationService"

export default function NouveauVoyageur() {
    // input recherche voyageur
    const [valueSearch, setValueSearch] = useState("")
    const [isCompletInfo, setIsCompletInfo] = useState(false)
    // input form voyageur
    const [selectedOption, setSelectedOption] = useState("");
    const [numero_passport, setNumero_passport] = useState("");
    const [isValidPasseport, setIsValidPasseport] = useState("");
    const [isValidIndentite, setIsValidIndentite] = useState("");
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");

    // input form resevation
    const [idVoyageur, setIdVoyageur] = useState(null);
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const [nomReservation, setNomReservation] = useState("");
    const [chambres, setChambres] = useState([]);
    const [optionsChambres, setOptionsChambres] = useState([]);
    const [nombreChambres, setNombreChambres] = useState("");
    const [statut, setStatut] = useState("");
    const [montant, setMontant] = useState("");
    const [paiement, setPaiement] = useState("");
    const [source, setSource] = useState("");

    const completInfo = (voyageur) => {
        setIsCompletInfo(true)
        setSelectedOption("passeport")
        setNumero_passport(voyageur.numero_passport)
        setPrenom(voyageur.prenom)
        setNom(voyageur.nom)
        setEmail(voyageur.email)
        setTelephone(voyageur.telephone)
    }
    // Id hotelier connecté
    const userId = useSelector((state) => state.auth.user_id);
    // recupération chambre
    const { data: chambresData } = useGetRoomsQuery( userId )
    useEffect(() => {
        if (chambresData) {
            setOptionsChambres(chambresData.data);
        }
    }, [chambresData]);

    const dispatch = useDispatch();
    // ajout nouveau voyageur
    const [addNewVoyageurMutation] = useAddNewVoyageurMutation();
    const handleSubmitVoyageur = async () => {
        const voyageurData = {
            user: userId,
            numero_passport: numero_passport,
            prenom: prenom,
            nom: nom,
            email: email,
            adresse: "Ouakam",
            telephone: telephone,
        }
        try {
            const data = await addNewVoyageurMutation(voyageurData);
            dispatch(addNewVoyageur(voyageurData));
            setNumero_passport("")
            setPrenom("")
            setNom("")
            setEmail("")
            setTelephone("")
            const recupIdVoyageur = data.data.id
            setIdVoyageur(recupIdVoyageur)
            console.log("Voyageur ajouté avec succès!", data);
            // setIsLoadingVoyageur(true);
    } catch (error) {
        console.error("Erreur lors de l'ajout du voyageur :", error);
    }
    }
    // ajout nouveau reservation
    const [addNewReservationMutation] = useAddNewReservationMutation();
    const handleSubmitReservation = async () => {
        const chambresAssoci = chambres.map((chambre) => ({
            chambre: chambre.id
            // quantite: 0
        }));
            const reservationData = {
                user: userId,
                voyageur: idVoyageur,
                chambres_associes: chambresAssoci,
                nom_reservation: nomReservation,
                date_debut_sejour: dateDebut,
                date_fin_sejour: dateFin,
            prix: montant,
        };
        try {
                const data = await addNewReservationMutation(reservationData);
                dispatch(addNewReservation(reservationData));
            // setIsLoadingVoyageur(true);
                setStatut("")
                setDateDebut("")
                setDateFin("")
                setNomReservation("")
                setNumero_passport("")
                setChambres("")
                setNombreChambres("")
                setMontant("")
                setPaiement("")
                setSource("")
                console.log("Réservation ajoutée avec succés :", data);
        } catch (error) {
            console.error("Erreur lors de l'ajout de la reservation :", error);
        }
    }
    // handleSubmit
    const handleSubmit = async (e) => {
        e.preventDefault();
        handleSubmitReservation();
        handleSubmitVoyageur();
        alert("Voyageur ajouté avec succès!")
    };

    const { data: voyageursData } = useGetVoyageursQuery( userId )
    const voyageurs = voyageursData?.data || [];
    console.log({ voyageurs });
    return (
        <div className="container-fluid pt-5" style={styledRelationClientDetail.relationClient}>
            <Heading1
            style={{
                color: "#FFF",
                textDecoration: "underline",
                textAlign: "center",
                paddingTop: "50px",
                fontWeight: "400"
            }}
            >
          Nouveau Voyageur
        </Heading1>
            <ContainerDemandeClient className="container mt-5 mb-5 p-5">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="row card shadow pb-4 mb-5">
                            <Div className="col-5">INFORMATIONS DU VOYAGEUR</Div>
                            <FormInfoVoyageur
                                        selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                                        numero_passport={numero_passport} setNumero_passport={setNumero_passport}
                                        isValidIndentite={isValidIndentite} setIsValidIndentite={setIsValidIndentite}
                                        isValidPasseport={isValidPasseport} setIsValidPasseport={setIsValidPasseport}
                                        prenom={prenom} setPrenom={setPrenom}
                                        nom={nom} setNom={setNom}
                                        email={email} setEmail={setEmail}
                                        telephone={telephone} setTelephone={setTelephone}
                            />
                            <div className="row mt-4">
                                <div className="col-12 text-center mt-4">
                                    <span style={{ fontStyle: "italic" }}>Sélectionnez un réseau social pour compléter le profil du voyageur</span>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-center mt-4">
                                    <DivReseau className="shadow me-5" background="blue" color="white">
                                        <FaLinkedinIn />
                                    </DivReseau>
                                    <DivReseau className="shadow me-5" background="white">
                                        <FcGoogle />
                                    </DivReseau>
                                    <DivReseau className="shadow me-5" background="white" color="blue">
                                        <FaFacebookF />
                                    </DivReseau>
                                </div>
                            </div>
                        </div>
                        <div className="row card shadow pb-5">
                            <Div className="col-6">INFORMATIONS DE LA RESERVATION</Div>
                            <FormInfoResrvation
                                isCompletInfo={isCompletInfo}
                                dateDebut={dateDebut} setDateDebut={setDateDebut}
                                dateFin={dateFin} setDateFin={setDateFin}
                                nomReservation={nomReservation} setNomReservation={setNomReservation}
                                chambres={chambres} setChambres={setChambres}
                                optionsChambres={optionsChambres} setOptionsChambres={setOptionsChambres}
                                nombreChambres={nombreChambres} setNombreChambres={setNombreChambres}
                                statut={statut} setStatut={setStatut}
                                montant={montant} setMontant={setMontant}
                                paiement={paiement} setPaiement={setPaiement}
                                source={source} setSource={setSource}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="card shadow p-5">
                            <Heading5 className="text-center">SELECTIONNEZ UN VOYAGEUR</Heading5>
                            <SearchInput className="mt-4" value={valueSearch} onChange={(e) => setValueSearch(e.target.value)} placeholder="Rechercher par nom" />
                            {isCompletInfo ?
                                <DivDotted border= "none" background="#E0E0E0" radius="5px" className="mt-4 ps-2 py-3 d-flex align-items-center shadow">
                                    <DivProfil className="d-flex align-items-center justify-content-center me-3">M</DivProfil>
                                    <span style={{ fontStyle: "italic" }}>{prenom} {nom}</span>
                                </DivDotted> :
                                    <DivDotted border= "3px dotted #E0E0E0" className="mt-4 ps-2 pb-3 d-flex flex-column">
                                        {voyageurs.map((voyageur, index)=> (
                                            <div key={index} style={{ cursor: "pointer" }} className=" d-flex align-items-center pb-3" onClick={() => completInfo(voyageur)}>
                                                <DivProfil className="d-flex align-items-center justify-content-center me-3">M</DivProfil>
                                                <span style={{ fontStyle: "italic" }}>{voyageur.prenom} {voyageur.nom}</span>
                                            </div>
                                        ))}
                                    </DivDotted>
                            }
                            {isCompletInfo?
                                <>
                                <DivDotted border= "3px dotted #E0E0E0" className="mt-4 pb-3 d-flex align-items-center">
                                    <span style={{ fontStyle: "blod" }}>RECAPITULATIFS</span>
                                </DivDotted>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Nom et prénom</span> <span> : {prenom} {nom}</span>
                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">E-mail</span> <span> : {email}</span>
                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Numéro de téléphone</span> <span> : {telephone}</span>
                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Titre</span> <span> : {selectedOption}</span>
                                </p>
                                <p>
                                <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Numéro de passeport</span> <span> : {numero_passport}</span>
                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Etat</span> <span> : Sénégal</span>
                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Ville</span> <span> : Dakar</span>

                                </p>
                                <p>
                                    <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Adresse</span> <span> : Ouakam</span>

                                </p>
                                <p>
                                <span style={{ textDecoration: "2px underline #ccc" }} className="mt-2">Profession</span> <span> : Designer</span>
                                </p>
                                </> : null
                            }
                        </div>
                    </div>
                </div>
                <div className="row d-flex mt-5">
                    <div className="col-12">
                        <Link to={`/${ADMIN_PATH}/${GESTION_VOYAGEUR_PATH}`}>
                            <Button background="#FFC857" color="#000" borderColor="none">Sauvegarder et quitter</Button>
                        </Link>
                        <Link to="#">
                        {/* {isLoadingVoyageur ?
                                <Button background="#FFF" color="#FFC857" borderColor="1px solid #FFC857" disabled>Chargement...</Button> :
                                <Button onClick={handleSubmit} background="#FFF" color="#FFC857" borderColor="1px solid #FFC857">Sauvegarder</Button>
                        } */}
                            <Button onClick={handleSubmit} background="#FFF" color="#FFC857" borderColor="1px solid #FFC857">Sauvegarder</Button>
                        </Link>
                        <Link to={`/${ADMIN_PATH}/${GESTION_VOYAGEUR_PATH}`}>
                            <Button background="#FFF" color="#FFC857" borderColor="1px solid #FFC857">Annuler</Button>
                        </Link>
                    </div>
                </div>
            </ContainerDemandeClient>
        </div>
      )
}
