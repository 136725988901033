import React from "react";
import styled from "styled-components";

const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  gap: 10px;
`;

const Image = styled.img`
  
`;

const Text = styled.p`
  font-size: 15px;
  margin-top: 17px;
`;

const CardHeader = ({ image, text }) => {
  return (
    <CardHeaderContainer>
      <Image src={image} />
      <Text>{text}</Text>
    </CardHeaderContainer>
  );
};

export default CardHeader;
