import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import hotels from "../../image/hotels.png";
import CustomButton from "components/Boutton/CustomButton";
import Input from "components/Inputs/Input";
import { useForgotPasswordMutation } from "services/auth-services/AuthService";
import { ConnexionBody } from "components/ConnexionForm/LoginPageStyle";
import { RESETPASSWORD } from "routes/navigation/navigationPaths";
// import BgGroup from "../../image/BgGroup.png"

const ConnexionFormMdpOublie = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [forgotPassword] = useForgotPasswordMutation();
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await forgotPassword({ email });
            navigate(`${RESETPASSWORD}`);
        } catch (error) {
            console.error("Erreur lors de la demande de réinitialisation de mot de passe :", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ConnexionBody className="d-flex justify-content-center align-items-center">
            <div className="containerrr">
                <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                    <div className="mb-1">  <img src={hotels} alt="Logo" /> </div>
                    <form className="auth-forms p-4" autoComplete="off" onSubmit={handleSubmit}>
                        <div className="d-flex flex-column gap-2 bg-white rounded" style={{ padding: "20px" }}>
                            <div style={{ fontSize: "16px" }}>Mot de passe oublié ?</div>
                            <div>
                                <p style={{ width: "270px", fontSize: "14px" }}>Entrez votre adresse e-mail
                                    ci-dessous et nous vous enverrons des instructions
                                    sur la façon de modifier votre mot de passe.</p>
                            </div>
                            <div>
                                <Input
                                    type="email"
                                    name="email"
                                    className="input-form border-2 border-bottom border-light-subtle"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="text-center">
                                <CustomButton
                                    type="submit"
                                    className="btn w-75 fw-bold"
                                    backgroundColor="#FFC857"
                                    color="#000000"
                                    border="none"
                                    fontSize="16px"
                                >
                                    <span style={{ color: "#000000" }}>
                                        {loading ? "Chargement..." : "Envoyer"}
                                    </span>
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                    <div className="text-center d-flex gap-1">
                        <p className="text-white"> Revenir à la</p>
                        <Link to="/login">
                            <span style={{ color: "#FFD964", fontSize: "16px", textDecoration: "none" }}>connexion</span>
                        </Link>
                    </div>
                </div>
            </div>
        </ConnexionBody>
    );
};

export default ConnexionFormMdpOublie;
