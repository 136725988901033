import React from "react"
import { ContainerAdminTextPrep, ContentTextPrep, SidebarTextPrep, ContenuTextPrep } from "../../StyledAdmin"
import { Heading1 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import SidebarTextPrepares from "components/AdminTextPrepares/TextesPrepares/SidebarTextPrepares";
import { Outlet, useLocation } from "react-router-dom";
import { TEXTESPREPARES_NEW, ADMIN_PATH, TEXTESPREPARES } from "routes/navigation/navigationPaths";

function TextesPrepares() {
    const location = useLocation();
  return (
    <ContentTextPrep className="container-fluid pt-5">
        <Heading1
        style={{
            color: "#FFF",
            textDecoration: "underline",
            textAlign: "center",
            paddingTop: "50px",
            fontWeight: "400"
        }}
        >
        {location.pathname === `/${ADMIN_PATH}/${TEXTESPREPARES}`? "Textes préparés" :
        location.pathname === `/${ADMIN_PATH}/${TEXTESPREPARES}/${TEXTESPREPARES_NEW}`? "Nouveau Email" : ""}
        </Heading1>
        <ContainerAdminTextPrep className="container mt-5 mb-3 pb-5">
            <div className="row">
                <div className="row">
                    <SidebarTextPrep className="col-md-3 py-3" style={{ borderRadius: "14px" }}>
                        <SidebarTextPrepares />
                    </SidebarTextPrep>
                    <ContenuTextPrep className="col-md-9">
                        <Outlet />
                    </ContenuTextPrep>
                </div>
            </div>
        </ContainerAdminTextPrep>
    </ContentTextPrep>
  )
}

export default TextesPrepares