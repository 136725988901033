import ConnexionProviderResetPwd from "components/ConnexionFormResetMdp/ConnexionFormResetPwdProvider"
import ExempleConnexionFormReset from "components/ConnexionFormResetMdp/ExempleConnexionFormReset"
import React from "react"

function AddNewPassord() {
  return (
    <ConnexionProviderResetPwd>
        <ExempleConnexionFormReset />
    </ConnexionProviderResetPwd>
  )
}

export default AddNewPassord