import InfoPersonnel from "components/RelationClientDetail/InfoClient/InfoPersonnel";
import React from "react";
import styled from "styled-components";

const InfoCardContain = styled.div`
  padding: ${ (props) => props.padding };
  box-shadow: 1px 1px 3px gray;
  margin: 10px;
  border-radius: 9px;
`
const PrestaDate = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

const Date = styled.p`
  font-size: 14px;
  color: #667085;
`

const InfoCard = ({ padding, name, Pays, countryImage, preStayDate, size }) => {
  return (
    <InfoCardContain padding={padding} className="card-body row d-flex justify-content-center align-items-center" style={{ color: "#000", fontSize: size }}>
      <div className="col-md-4">
        <p className="card-title">{name}</p>
      </div>
      <div className="col-md-4 d-flex justify-content-center">
        <div>
          <p className="card-text">
            Pays
            <InfoPersonnel img={countryImage} value={Pays} />
          </p>
        </div>
      </div>
      <PrestaDate className="col-md-4">
        <Date>Prestay rempli le {preStayDate}</Date>
      </PrestaDate>
    </InfoCardContain>
  );
};

export default InfoCard;

