import React from "react";
import sender from "../../image/sender.png";
import concierge from "../../image/concierge.png";
import happy from "../../image/happy.png";
import ok from "../../image/ok.png";
import ph_star from "../../image/ph_star.png";
import InfoCard from "components/InfoCard/InfoCard";
import { Link } from "react-router-dom";
import CardHeader from "./CardHeader";
import { CardBody, CardColumn, OkIconContainer, PreTacheText } from "./Prestay";
import styled from "styled-components";
import { DEMANDE_CLIENT_PATH } from "routes/navigation/navigationPaths";
import { BsListCheck } from "react-icons/bs";
import DrapeauSenegal from "image/DrapeauSenegal.jpg"
import { BsArchive } from "react-icons/bs";

export const PsContain = styled.div`
  margin-bottom: 50px;
  font-family: Roboto;
`


const HistoriqueElementTraite = () => {
  return (
    <div className="row card d-flex flex-row justify-content-center" style={{ border: "none" }}>
      <div className="card-header d-flex flex-row align-items-center" style={{ backgroundColor: "#FFFFFF", height: "50px" }}>
            <BsListCheck className="me-1" /> A traiter / <BsArchive className="ms-4 me-1"/> Déja traiter
      </div>
      <div className="card-body">
        <PsContain>
          <CardHeader image={sender} text="Pre-Stay" />
          <Link to={`${DEMANDE_CLIENT_PATH}`}>
            <InfoCard padding="8px" size="15px" name="Rémy Mendy" Pays="Senegal" countryImage={DrapeauSenegal} preStayDate="13/03/2024" />
          </Link>
        </PsContain>

        {/*  */}
        <PsContain>
          <CardHeader image={concierge} text="Conciergerie" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>

        {/*  */}
        <PsContain>
          <CardHeader image={happy} text="In-Stay" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>

        {/*  */}
        <PsContain>
          <CardHeader image={ph_star} text="Post-Stay" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>
      </div>
    </div>
  );
};

export default HistoriqueElementTraite;