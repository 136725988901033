import React from "react";
import { RxPerson } from "react-icons/rx";

export default function PhotoProfile({ width, height, fontSize }) {
    return (
        <div
            style={{
                background: "#E76F51",
                color: "white",
                borderRadius: "50%",
                height: height,
                width: width,
                textAlign: "center",
                fontSize: fontSize
            }}>
            <RxPerson />
        </div>
    )
}
