// import { getUserStatus } from "redux/features/userSliceWithTokenValidation";

/**
 *
 * @return {true|false}
 */
function useIsAuthenticate() {
    const isAuthenticated = true;
    // const isAuthenticated = useSelector(getUserStatus);
    return isAuthenticated;
}

export default useIsAuthenticate;
