import React from "react"

function ReserveAndTotalCard({ number, title }) {
  return (
    <div className="col border rounded d-flex flex-column justify-content-center align-items-center py-4 bg-white">
      <span className="fw-bold fs-4">{number}</span>
      <span>{title}</span>
    </div>
  )
}

export default ReserveAndTotalCard
