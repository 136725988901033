import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer, persistStore
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import API from "services/auth-services/AuthService";
import userslice from "./features/userslice";
import userSliceWithTokenValidation from "./features/userSliceWithTokenValidation";
import voyageurApi from "services/Voyageurservices";
import { reservationApi } from "services/ReservationService";


const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
}

const rootReducers = combineReducers({
    auth: userSliceWithTokenValidation,
    user: userslice,
    [API.reducerPath]: API.reducer,
    [voyageurApi.reducerPath]: voyageurApi.reducer,
    [reservationApi.reducerPath]: reservationApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(API.middleware, voyageurApi.middleware, reservationApi.middleware),
})

// setupListeners(store.dispatch);

export const persistor = persistStore(store);