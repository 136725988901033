import React from "react";
import { RxPerson } from "react-icons/rx";
import { FiArchive } from "react-icons/fi";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";

function ConversationAtraiterCard({ text, date, colorIcon, sizeText }) {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="col-md-1 d-flex justify-content-center py-2">
        <div className="text-white rounded-circle fs-3 px-3 d-flex jutify-content-center align-items-center" style={{ height: "50px", width: "50px", background: "#E76F51" }}><RxPerson /></div>
      </div>
      <div className="col-md-10 card p-0"
        style={{
          marginTop: "5px",
          boxShadow: "1px 2px 3px gray",
          backgroundColor: "#fff",
        }}
      >
        <div className="" style={styledRelationClientDetail.arrowLeft}></div>
        <div className="card-body px-2 py-0">
          <div className="d-flex justify-content-between gap-1">
            <p style={sizeText} className="m-0">{text}</p>
            <FiArchive className="fs-1" style={{ color: `${colorIcon}` }} />
          </div>
          <p className="text-end p-0 m-0">{date}</p>
        </div>
      </div>
    </div>
  )
}

export default ConversationAtraiterCard
