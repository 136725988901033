import React from "react";
import SelectComponent from "./Select";
import { Input, Label } from "./NouveauVoyageurCss";

export default function FormInfoVoyageur({
    selectedOption, setSelectedOption,
    numero_passport, setNumero_passport,
    isValidIndentite, setIsValidIndentite,
    isValidPasseport, setIsValidPasseport,
    prenom, setPrenom,
    nom, setNom,
    email, setEmail,
    telephone, setTelephone
  }) {
  const options = [
    { value: "passeport", text: "Passeport" },
    { value: "cartedidenté", text: "Carte d'identé national" }
  ];
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setNumero_passport("")
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (selectedOption === "cartedidenté") {
      const isValidInput = /^\d{13}$/.test(inputValue);
      setIsValidIndentite(isValidInput);
    } else {
      const isValidInput = /^[A-Z]{2}[0-9]{6,9}$/i.test(inputValue);
      setIsValidPasseport(isValidInput);
    }
    setNumero_passport(e.target.value);
  };


  return (
    <form className="row d-flex flex-column align-items-center px-3">
      <div className="row mt-4">
        <div className="d-flex flex-column col-12 col-md-6">
          <Label style={{ fontWeight: "500" }} className="mb-1">Sélectionnez le titre</Label>
          <SelectComponent
            options={options}
            value={selectedOption}
            onChange={handleSelectChange}
          />
        </div>
        <div className="d-flex flex-column col-12 col-md-6">
          <Label className="mb-1">Numéro de document</Label>
          <Input
            type="text"
            value={numero_passport}
            onChange={handleInputChange}
          />
          {!isValidIndentite && selectedOption === "cartedidenté" && numero_passport != "" && (
            <p style={{ color: "red" }}>Numéro de carte d'identité invalide.</p>
          )}
          {!isValidPasseport && selectedOption === "passeport" && numero_passport != "" && (
            <p style={{ color: "red" }}>Numéro de passeport invalide.</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-12 col-md-6">
          <Label className="mb-1">Prénom</Label>
          <Input className="" value={prenom} onChange={(e)=>setPrenom(e.target.value)} />
          {prenom.length >= 100 ?
            <p style={{ color: "red" }}>Prénom trop long (maximum 100 caractères).</p> : null
          }
        </div>
        <div className="d-flex flex-column col-12 col-md-6">
          <Label className="mb-1">Nom</Label>
          <Input className="" value={nom} onChange={(e)=>setNom(e.target.value)} />
          {nom.length >= 100 ?
            <p style={{ color: "red" }}>Nom trop long (maximum 100 caractères).</p> : null
          }
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-12 col-md-6">
          <Label className="mb-1">E-mail</Label>
          <Input type="email" className="" value={email} onChange={(e)=>setEmail(e.target.value)} />
        </div>
        <div className="d-flex flex-column col-12 col-md-6">
          <Label className="mb-1">Numéro de téléphone</Label>
          <Input type="tel" className="" value={telephone} onChange={(e)=>setTelephone(e.target.value)} />
        </div>
      </div>
    </form>
  );
}
