// import Input from "components/Inputs/Input";
import "./formeStyle.css";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import hotels from "../../image/hotels.png";
import { ConnexionBody } from "./LoginPageStyle";
import { RELATION_CLIENT_PATH } from "routes/navigation/navigationPaths";
import { useLoginUserMutation } from "services/auth-services/AuthService";
import { setAccessToken, setRefreshToken, setUser, setFirstName,
setLastName, getUserStatus, addNewVoyageur } from "redux/features/userSliceWithTokenValidation";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "utils/Variables/color";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";

const ConnexionForm = () => {
  const { register, handleSubmit, formState, reset } = useFormContext();
  const [loginUser, { isLoading }] = useLoginUserMutation()
  const [remember, setRemember] = useState(false);
  const isAuthentificate = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    const { data: userData, error } = await loginUser(data);
    try {
      if (error) {
        if (error.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Vous n'êtes pas autorisé à vous connecter.",
            showConfirmButton: false,
            timer: 1500,
          });
          reset();
        } else {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de la connexion.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        if (userData.status === "HOTELIER") {
          const userId = userData.id;
          dispatch(setUser(userId));
          dispatch(setAccessToken(userData.access));
          dispatch(setRefreshToken(userData.refresh));
          dispatch(setFirstName(userData.first_name));
          dispatch(setLastName(userData.last_name));
          dispatch(addNewVoyageur(userData.voyageur));
          reset();
          navigate(`/${RELATION_CLIENT_PATH}`);
        }
      }
    } catch (err) {
      console.log("Error during login:", err);
    }
  };

  return isAuthentificate ? (
    <Navigate to={`/${RELATION_CLIENT_PATH}`} replace />
  ) : (<ConnexionBody className="d-flex justify-content-center align-items-center">
      <div className="containerrr">
        <div>
          <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
            <div className="mb-3">  <img src={hotels} /> </div>
            <form className="auth-forms p-4" autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
              <div
                className="d-flex flex-column gap-3 rounded"
                style={{ padding: " 20px", backgroundColor: "#fff" }}
              >
                <div style={{ fontSize: "17px" }} >
                  Connectez-vous en tant que Hôtelier
                </div>
                <div>
                  <input
                    type="email"
                    className="input-form border-2 border-bottom border-light-subtle"
                    id="email"
                    placeholder="Email"
                    {...register("email", { required: true })}
                  />
                  {formState.errors && formState.errors.email && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.email?.message}
                    </div>
                  )}
                </div>

                <div>
                  <input
                    type="password"
                    className="input-form border-2 border-bottom border-light-subtle"
                    id="password"
                    placeholder="Mot de passe"
                    {...register("password", { required: true })}
                  />
                  {formState.errors && formState.errors.password && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState.errors.password?.message}
                    </div>
                  )}
                  <div className="extra justify-content-between">
                    <div className="my-4">
                      <div className="form-check d-flex align-items-center gap-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember"
                          style={{
                            width: "24px",
                            height: "24px",
                            border: "2.67px solid #9E9E9E",
                          }}
                          checked={remember}
                          onChange={(e) => setRemember(e.currentTarget.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                            fontFamily: "Roboto",
                            fontSize: "18.67px",
                            fontWeight: 400,
                            marginTop: "2px",
                          }}
                        >
                          Gardez-moi connecté
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="w-100"
                    style={{
                      backgroundColor: `${COLORS.activeColor}`,
                      borderRadius: "5.333px",
                      padding: "12px 0px",
                      border: "none",
                      fontSize: "21.33px",
                      fontWeight: "500",
                      color: "#000"
                    }}
                  >
                    <span>
                      {isLoading ? "Connexion..." : "Se connecter"}
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <div>
              <div className="text-center">
                <Link to="/auth/forgetPassword">
                  <span
                    style={{
                      color: "#FFD964",
                      fontSize: "18.667px",
                      textDecoration: "none",
                    }}
                  >
                    Mot de passe oublié?{" "}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConnexionBody>
  )
};

export default ConnexionForm;
