import AdminContain from "components/AdminContain/AdminContain"
import { Heading1 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import React from "react"
import BgGroup from "../../../image/BgGroup.png"
import styled from "styled-components"


const ContainerAdmin = styled.div`
    padding-bottom:30px;
    background-image: url(${BgGroup});
    background-size:cover;
    background-repeat:no-repeat;
`
const DashboardAdmin = () => {
    return (
        <ContainerAdmin className="mt-5">
            <Heading1>
                Admin
            </Heading1>
            <AdminContain />

        </ContainerAdmin>
    )
}

export default DashboardAdmin
