import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "redux/features/userSliceWithTokenValidation";
import api from "routes/api/global-vars";
import { reservationEndpoint, roomsEndpoint } from "routes/api/endpoints"

export const reservationApi = createApi({
    reducerPath: "reservationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers, { getState }) => {
            const token = selectToken(getState());
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        addNewReservation: builder.mutation({
            query: (reservationData) => ({
                url: reservationEndpoint.addNewReservation(),
                method: "POST",
                body: reservationData,
            }),
        }),
        getReservations: builder.query({
            query: (userId) => ({
                url: reservationEndpoint.addNewReservation(),
                method: "GET",
                params: { user: userId },
            }),
        }),
        getRooms: builder.query({
            query: (userId) => ({
                url: roomsEndpoint.getRooms(),
                method: "GET",
                params: { user: userId },
            }),
        }),
    }),
});

export const { useAddNewReservationMutation, useGetRoomsQuery, useGetReservationsQuery } = reservationApi;