import React from "react";
import { FiCalendar } from "react-icons/fi";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import { RxDotFilled } from "react-icons/rx";

function HistoriqueCard({ montant, debut, fin, number, id }) {
    return (
        <div className="card-body py-2 px-1 row rounded" style={{
            marginTop: "5px",
            boxShadow: "1px 1px 3px gray",
            margin: "15px",
            padding: "0px",
            backgroundColor: "#fff"
        }} id={id}>
            <div className="col-4 fw-medium">
                <p className=" m-0">Réservation n°{number}</p>
                <p className="m-0">Montant: {montant} FXOF</p>
            </div>
            <div className="col-3 p-0 fw-medium">
                <p className=" m-0 d-flex align-items-center gap-1"><FiCalendar /> Début</p>
                <p className="">{debut}</p>
            </div>
            <div className="col-3 p-0 fw-medium">
                <p className=" m-0 d-flex align-items-center gap-1"><FiCalendar /> Fin</p>
                <p className="">{fin}</p>
            </div>
            <div className="col-2 d-flex align-items-center p-0">
                <span className="status rounded-pill p-1" style={styledRelationClientDetail.statusHistorique}><RxDotFilled className="fs-5" />En cours</span>
            </div>
        </div>
    )
}

export default HistoriqueCard