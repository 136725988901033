import React from "react"
import { Paragraph } from "./InfoClientCSS";

export default function ReseauSociaux({ socialMediaData }) {
    return (
        <div>
            {socialMediaData.map((socialMedia, index) => (
                <div key={index}>
                    <div className="d-flex">
                        <div className="d-flex justify-content-center align-items-center"
                            style={{
                                background: socialMedia.background,
                                color: socialMedia.color,
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                textAlign: "center",
                                fontSize: "18px",
                                border: `1px solid ${socialMedia.border}`
                            }}
                        >
                            {socialMedia.logo}
                        </div>
                        <div className="d-flex flex-column ms-2">
                            <Paragraph style={{ color: "blue" }}>{socialMedia.name}</Paragraph>
                            <Paragraph>Connecté {socialMedia.followers}</Paragraph>
                            <Paragraph>{socialMedia.description}</Paragraph>
                        </div>
                    </div>
                    {index !== socialMediaData.length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
}


