/*
|--------------------------------------------------------------------------
! Add Your Application andpoints here
|--------------------------------------------------------------------------
*/
export const authentificate = {
    onLogin() {
        return "auth/login/"
    },
    onLogout() {
        return "auth/logout/"
    },
    onRefreshToken() {
        return "auth/refresh/"
    },
    onForgotPassword() {
        return "request-reset-password/"
    },
    onResetPassword() {
        return "reset-password/"
    }
}


export const voyageurEndpoint = {
    addNewVoyageur() {
        return "voyageur/";
    },
    // Autres méthodes
};

export const reservationEndpoint = {
    addNewReservation() {
        return "reservation/";
    },
    // Autres méthodes
};

export const roomsEndpoint = {
    getRooms() {
        return "chambre/";
    },
    // Autres méthodes
};
