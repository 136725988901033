import React from "react";
import SelectComponent from "./Select";
import { Input, Label } from "./NouveauVoyageurCss";
import InputCalendrier from "./InputCalendrier";
import SelectChambre from "./SelectChambre";

export default function FormInfoResrvation({
  dateDebut, setDateDebut,
  dateFin, setDateFin,
  nomReservation, setNomReservation,
  chambres, setChambres,
  nombreChambres, setNombreChambres,
  statut, setStatut,
  montant, setMontant,
  paiement, setPaiement,
  source, setSource,
  optionsChambres
}) {
    const optionsStatut = [
        { value: "encours", text: "En cours" },
        { value: "Terminer", text: "Terminer" },
        { value: "Annuler", text: "Annuler" }
    ];
    const optionsPaiement = [
        { value: "Payée", text: "Payée" },
        { value: "Impayée", text: "Impayée" }
    ];
    const optionsModePaiement = [
        { value: "OrangeMoney", text: "Orange Money" },
        { value: "Wave", text: "Wave" },
        { value: "Caisse", text: "Caisse" },
        { value: "Banque", text: "Banque" }
    ];
    // const [date, setDate] = useState(null);

  return (
    <form className="row d-flex flex-column align-items-center px-3">
    <div className="row mt-4">
      <div className="d-flex flex-column col-12 col-md-6">
        <InputCalendrier date={dateDebut} setDate={setDateDebut} label="Date de début du séjour"/>
      </div>
      <div className="d-flex flex-column col-12 col-md-6">
        <InputCalendrier date={dateFin} setDate={setDateFin} label="Date de fin du séjour"/>
      </div>
    </div>
    <div className="row mt-4">
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Chambre disponible</Label>
        <SelectChambre chambres={chambres} setChambres={setChambres} setNombreChambres={setNombreChambres} optionsChambres={optionsChambres} />
        {/* <SelectComponent
          options={optionsChambre}
          value={chambres}
          onChange={handleChange}
        /> */}
      </div>
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Nombre de chambre</Label>
        <Input
        type="text"
        value={nombreChambres}
      />
      </div>
    </div>
    <div className="row mt-4">
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Statut de réservation</Label>
        <SelectComponent
          options={optionsStatut}
          value={statut}
          onChange={(e)=>setStatut(e.target.value)}
        />
      </div>
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Montant du paiement</Label>
        <Input
        type="text"
        value={montant}
        onChange={(e)=>setMontant(e.target.value)}
      />
      </div>
    </div>
    <div className="row mt-4">
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Statut du paiement</Label>
        <SelectComponent
          options={optionsPaiement}
          value={paiement}
          onChange={(e)=>setPaiement(e.target.value)}
        />
      </div>
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Source de Paiement</Label>
        <SelectComponent
          options={optionsModePaiement}
          value={source}
          onChange={(e)=>setSource(e.target.value)}
        />
      </div>
    </div>
    <div className="row mt-4">
      <div className="d-flex flex-column col-12 col-md-6">
        <Label className="mb-1">Nom de la réservation</Label>
        <Input
        type="text"
        value={nomReservation}
        onChange={(e)=>setNomReservation(e.target.value)}
      />
      </div>
    </div>
    </form>
  );
}
