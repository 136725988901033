
import React from "react"
import { Img } from "./InfoClientCSS";

export default function InfoPersonnel({ label, img, value, ms }) {
  return (
    <div style={{ fontFamily: "Roboto" }}>
      <span>{label}</span>
      <span className={ms}>
        {
          img ? <Img src={img} alt="" className="me-3" /> : null
        }
        {value}
      </span>
    </div>
  );
}
