/* eslint-disable react/react-in-jsx-scope */
// import HomePage from "pages/Home/HomePage";
import {
  ADMIN_PATH,
  GESTION_PATH,
  MARKETING_PATH,
  PLUS,
  RELATION_CLIENT_PATH,
  STATISTIQUE,
  DETAIL_CLIENT,
  DASHBOARD_ADMIN_PATH,
  TEXTESPREPARES,
  TEXTESPREPARES_ALL,
  TEXTESPREPARES_MESSAGES,
  TEXTESPREPARES_FORMULAIRE,
  ELEMENT_TRAITE,
  DEMANDE_CLIENT_PATH,
  TEXTESPREPARES_NEW,
  GESTION_VOYAGEUR_PATH,
  NOUVEAU_VOYAGEUR_PATH,
  LOGIN_PATH,
  FORGETPASSWORD,
  GESTION_RESERVATION_PATH,
  NOUVELLE_RESERVATION_PATH,
  RESETPASSWORD

} from "../navigationPaths";
import RequireAuth from "layouts/RequireAuth";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Navigate } from "react-router-dom";
import SplitShell from "layouts/AppShells/SplitShell";
import RelationClient from "pages/RelationClient/RelationClient";
import Marketing from "pages/Marketing/Marketing";
// import Admin from "pages/Admin/Admin";
import DashboardRelation from "pages/RelationClient/DashboardRelationClient/DashboardRelation";
import GestionClient from "pages/RelationClient/GEstionClient/GestionClientt";
import Statistique from "pages/RelationClient/Statististique/Statistique";
import Plus from "pages/RelationClient/Plus/Plus";
import RelationClientDetail from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetail";
import ElementTraite from "components/ElementTraite/ElementTraite";
import DemandeClient from "pages/RelationClient/DashboardRelationClient/DemandeClient/DemandeClient";
import DashboardAdmin from "pages/Admin/DashboardAdmin/DashboardAdmin";
import Admin from "pages/Admin/Admin";
import NouvelleEmail from "components/AdminTextPrepares/NouvelleEmail/NouvelleEmail";
import TableEmailTextPrepares from "components/AdminTextPrepares/TextesPrepares/TableEmailTextPrepares";
// import NouveauVoyageur from "components/AdminContain/NouveauVoyageur/NouveauVoyageur";

import TextesPrepares from "pages/Admin/DashboardAdmin/TextesPrepares/TextesPrepares";
import GestionVoyageur from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageur";
import ConnexionPage from "pages/connexion/ConnexionPage";
import NouveauVoyageur from "components/AdminContain/NouveauVoyageur/NouveauVoyageur";
import ConnexionFormMdpOublie from "components/ConnexionFormMdpOublie/ConnexionFormMdpOublie";
import GestionReservation from "pages/Admin/DashboardAdmin/GestionReservation/GestionReservation";
import NouvelleReservation from "pages/Admin/DashboardAdmin/GestionReservation/NouveauReservation/NouvelleReservation";
// import ConnexionFormResetMdp from "components/ConnexionFormResetMdp/ConnexionFormResetMdp";
import AddNewPassord from "pages/AddNewPassword/AddNewPassord";

/*
|---------------------------------------------------------------
| Les routes privates
|---------------------------------------------------------------
| Toute page qui necessite une authentification doit etre ici
|
*/

export const privateRoutes = [
  {
    path: "/",
    element: <Navigate to="login" />,
  },
  {
    path: LOGIN_PATH,
    element: <ConnexionPage />
  },
  {
    path: FORGETPASSWORD,
    element: <ConnexionFormMdpOublie />
  },
  {
    path: RESETPASSWORD,
    element: <AddNewPassord />
  },
  {
    path: "/*",
    errorElement: <ErrorBoundary />,
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: <SplitShell />,
        children: [
          {
            path: RELATION_CLIENT_PATH,
            element: <RelationClient />,
            breadcrumb: "Relation Client",
            children: [
              {
                path: "",
                element: <DashboardRelation />,
              },
              {
                path: DETAIL_CLIENT,
                element: <RelationClientDetail />,
                breadcrumb: "Detail Client"
              },
              {
                path: GESTION_PATH,
                path: ELEMENT_TRAITE,
                element: <ElementTraite />,
                breadcrumb: "Element Traité"
              },
              {
                path: `${ELEMENT_TRAITE}/${DEMANDE_CLIENT_PATH}`,
                element: <DemandeClient />,
                breadcrumb: "Demande Client"
              },
              {
                path: GESTION_PATH,
                element: <GestionClient />,
                breadcrumb: "Gestion Client"
              },
              {
                path: STATISTIQUE,
                element: <Statistique />,
              },
              {
                path: PLUS,
                element: <Plus />,
              },
            ]
          },
          {
            path: MARKETING_PATH,
            element: <Marketing />,
            breadcrumb: "Marketing"
          },
          {
            path: ADMIN_PATH,
            element: <Admin />,
            breadcrumb: "Admin",
            children: [
              {
                path: "",
                element: <DashboardAdmin />,
              },
              {
                path: DASHBOARD_ADMIN_PATH,
                element: <DashboardAdmin />,
              },
              {
                path: TEXTESPREPARES,
                element: <TextesPrepares />,
                breadcrumb: "Textes Préparés",
                children: [
                  {
                    path: TEXTESPREPARES_ALL,
                    element: "ALL"
                  },
                  {
                    path: TEXTESPREPARES_MESSAGES,
                    element: "MESSAGES"
                  },
                  {
                    path: TEXTESPREPARES_FORMULAIRE,
                    element: "FORMULAIRE"
                  },
                  {
                    path: TEXTESPREPARES_NEW,
                    element: <NouvelleEmail />
                  },
                  {
                    path: "",
                    element: <TableEmailTextPrepares />
                  }
                ]
              },
              {
                path: GESTION_VOYAGEUR_PATH,
                element: <GestionVoyageur />,
                breadcrumb: "Gestion Voyageur"
              },
              {
                path: NOUVEAU_VOYAGEUR_PATH,
                element: <NouveauVoyageur />,
                breadcrumb: "Nouveau Voyageur"
              },
              {
                path: GESTION_RESERVATION_PATH,
                element: <GestionReservation />,
                breadcrumb: "Gestion reservation"
              },
              {
                path: NOUVELLE_RESERVATION_PATH,
                element: <NouvelleReservation />,
                breadcrumb: "Nouvelle reservation"
              }

            ]
          }
        ],
      },
    ],
  },
];