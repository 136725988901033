import React, { useState } from "react"
import CustomButton from "components/Boutton/CustomButton";
import { HiOutlineArrowPath } from "react-icons/hi2";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Calendar } from "primereact/calendar";
// import InputCalendar from "./InputCalendar";
import CadreTableau from "./CadreTableau";
import { SearchInput } from "components/HeaderListe/Navbarcss";
import { Link } from "react-router-dom";
import ImportModal from "./ImportModal";
import { Cadre, CadreHeader, CadreHeader2, CadreTab, Container, GrpBtn, Icone, InputDate, Navig, Select, TextBut, Title } from "./GestionVoyageurCss";

const GestionVoyageur = () => {
  const adresseOptions = ["Dakar", "Pikine", "Guediawaye", "Rufisque", "Ouakam"];
  const statusOptions = ["En cours", "Terminée", "Annulée"];
  const [date, setDate] = useState(null);
  const [valueSearch, setValueSearch] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showTableBody, setShowTableBody] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleTableBody = () => {
    setShowTableBody(!showTableBody);
  };
  return (
    <Container>
        <Title>
          Gestion des voyageurs
        </Title>
        <Cadre>
          <CadreHeader>
            <Navig>
              <p className="text-primary">Admin</p>
              <p>/</p>
              <p>Voyageurs</p>
            </Navig>
            <SearchInput value={valueSearch} onChange={(e) => setValueSearch(e.target.value)} placeholder="Rechercher un voyageur" />
            <GrpBtn>
              <Link onClick={toggleTableBody}>
                <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                  <Icone> <HiOutlineArrowPath /> </Icone>
                  <TextBut className="d-flex justify-content-center align-items-center">Synchronisation</TextBut>
                </CustomButton>
              </Link>
              <Link onClick={openModal}>
                <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                  <Icone> <MdOutlineFileDownload /> </Icone>
                  <TextBut>Importer Excel/CSV</TextBut>
                </CustomButton>
              </Link>
              <Link to="nouvau-voyageur">
                <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                  <Icone> <FaPlus /> </Icone>
                  <TextBut>Nouveau voyageur</TextBut>
                </CustomButton>
              </Link>
            </GrpBtn>
          </CadreHeader>
          <CadreHeader2 className="ms-3 row">
            <div style={{ fontWeight: "500" }} className="col-1">Filtrer par</div>
            <InputDate className="col-2">
              <Calendar style={{ fontSize: "12px" }} id="buttondisplay" placeholder="Date début de séjour" value={date} onChange={(e) => setDate(e.value)} showIcon />
            </InputDate>
            <InputDate className="col-2">
              <Calendar style={{ fontSize: "12px" }} id="buttondisplay" placeholder="Date fin de séjour" value={date} onChange={(e) => setDate(e.value)} showIcon />
            </InputDate>
            <Select className="col-1">
              <option value="">Adresse</option>
              {adresseOptions.map((adresse, index) => (
                <option key={index} value={adresse}>{adresse}</option>
              ))}
            </Select>
            <Select className="col-1">
              <option value="">Status</option>
              {statusOptions.map((status, index) => (
                <option key={index} value={status}>{status}</option>
              ))}
            </Select>
          </CadreHeader2>
          <CadreTab>
            <CadreTableau showTableBody={showTableBody} />
          </CadreTab>
        </Cadre>
        <ImportModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </Container>
  )
}
export default GestionVoyageur;