export const COLUMNS = [
    {
        Header: "Marquer",
        accessor: "marquer"
    },
    {
        Header: "Demander à",
        accessor: "demander_a"
    },
    {
        Header: "Accessoires",
        accessor: "accessoires"
    },
    {
        Header: "Voyageur",
        accessor: "voyageur"
    },
    {
        Header: "Periode",
        accessor: "periode"
    },

    {
        Header: "Actions",
        accessor: "actions"
    },
]