import React from "react";
import CustomButton from "components/Boutton/CustomButton";

const CalendrierduClient = () => {
  return (
    <div className="row card d-flex flex-row justify-content-center" style={{ border: "none" }}>
      <div className="card-header " style={{ backgroundColor: "#FFFFFF" }}>
        <div className="row">
          <div className="col-md-6">
            <CustomButton className="btn btn-primary"
              backgroundColor="#E76F51"
              color="white"
              border="none"
              margin='0 10px 0 0'
              padding="10px 20px"
              fontSize="14px">
              Arrivées du jour
            </CustomButton>
            <CustomButton
              className="btn"
              color="black"
              padding="10px 20px"
              margin='0 10px 0 0'
              background="transparent"
              border="1px solid #E76F51"
              fontSize="14px"
            >Présences du jour
            </CustomButton>
            <CustomButton
              className="btn"
              color="black"
              padding="10px 20px"
              margin='0 10px 0 0'
              backgroundColor="transparent"
              border="1px solid #E76F51"
              fontSize="14px"
            >Départ du jour
            </CustomButton>
          </div>

          <div className="col-md-6">
            <div className="row align-items-center">
              <p
                className="col-auto"
                style={{
                  color: "white",
                  marginRight: "10px",
                  fontSize: "11px",
                  marginTop: "15px",
                }}
              >
                Tri
              </p>
              <div className="col">
                <CustomButton
                  className="btn"
                  color="black"
                  backgroundColor="#FFC857"
                  border="none"
                  margin='0 14px 0 0'
                  width="171px"
                  fontSize="14px"
                >
                  Ordre alphabétique
                </CustomButton>
              </div>
              <div className="col">
                <input
                  type="date"
                  className="form-control"
                  style={{
                    background: "#FFC857",
                    color: "black",
                    fontSize: "14px",
                  }}
                />
              </div>
              <div className="col">
                <CustomButton
                  className="btn"
                  margin='0 10px 0 0'
                  backgroundColor="#FFC857"
                  color="black"
                  border="none"
                  fontSize="14px"
                >
                  Extraire par
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr style={{ backgroundColor: "#F6F8F9" }}>
              <th scope="col" style={{ fontSize: "11px" }}>
                Client
              </th>
              <th scope="col" style={{ fontSize: "11px" }}>
                Arrivée{" "}
              </th>
              <th scope="col" style={{ fontSize: "11px" }}>
                Nuitées
              </th>
              <th scope="col" style={{ fontSize: "11px" }}>
                Info Pre-Stay
              </th>
              <th scope="col" style={{ fontSize: "11px" }}>
                Badge
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendrierduClient;
