import React from "react"
import CardInfoClient from "./CardInfoClient";
import { BiAlbum } from "react-icons/bi";
import { SlBadge } from "react-icons/sl";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { DivCentreInteret, Heading4, Paragraph } from "./InfoClientCSS";
import ReseauSociaux from "./ReseauSociaux";
import { RxPerson } from "react-icons/rx";
import { BiEditAlt } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";


export default function CardRight() {
  const socialMediaData = [
    { color: "white", background: "blue", name: "Remy Mendy", logo: <FaLinkedinIn />, followers: 1000, description: "Lorem ipsum dolor sit amet consectetur" },
    { color: "white", background: "black", name: "Remy Mendy", logo: <FaTwitter />, followers: 500 },
    { color: "blue", background: "white", border: "#E0E0E0", name: "Remy Mendy", logo: <FaFacebookF />, followers: 2000 },
  ];
  return (
    <CardInfoClient>
      <div className="shadow p-3 mb-3 d-flex flex-column justify-content-center">
        <Heading4>
          <BiAlbum /> A propos du client
        </Heading4>
      </div>
      <div className="card-body p-3">
        <div className="d-flex justify-content-between">
          <Heading4>Type de client</Heading4>
          <RxPerson />
        </div>
        <div className="d-flex flex-column">
          <span>Seul (4)</span>
          <span>Raison du voyage : Séminaire (4)</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <Heading4>Badges</Heading4>
          <BiEditAlt style={{ color: "blue" }} />
        </div>
        <SlBadge style={{ color: "#FFC857" }} />
        <hr />
        <Heading4>Réseau sociaux</Heading4>
        <ReseauSociaux socialMediaData={socialMediaData} />
        <hr />
        <div className="d-flex justify-content-between">
          <Heading4>Centre d'intéret</Heading4>
          <FaHeart style={{ color: "#E76F51", fontSize: "20px" }} />
        </div>
        <div className="d-flex flex-wrap">
          <DivCentreInteret>Sport</DivCentreInteret>
          <DivCentreInteret>Voyager</DivCentreInteret>
          <DivCentreInteret>Pick nick</DivCentreInteret>
          <DivCentreInteret>Film</DivCentreInteret>
          <DivCentreInteret>Lecture</DivCentreInteret>
          <DivCentreInteret>Documntaire</DivCentreInteret>
          <DivCentreInteret>Fottball</DivCentreInteret>
          <DivCentreInteret>Promenade</DivCentreInteret>
          <DivCentreInteret>+</DivCentreInteret>
        </div>
        <hr />
        <Heading4>Autres informations</Heading4>
        <Paragraph><FaCalendarAlt className="me-2" />Anniversaire durant le séjour</Paragraph>
      </div>
    </CardInfoClient>
  )
}
