
import styled from "styled-components";

export const ImgSearchInput = `
  data:image/svg+xml,
  %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E
    %3Cpath d='M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 
    0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%23aaa' /%3E
  %3C/svg%3E
`;


export const SearchInput = styled.input`
  font-size: 16px;
  padding: 14px 12px 14px 40px; 
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 250px;
  height: 20px;
  background-image: url("${ImgSearchInput}");
  background-repeat: no-repeat;
  background-position: 10px 50%; 
  background-size: 20px 20px;
  outline: none;

  ::placeholder {
    color: #aaa;
  }
  
  @media (max-width: 882px) { 
    width: 150px;
  }
  @media (max-width: 436px) { 
    width: 50px;
  }
  
`;