import React from "react";
import "./InfoClientCSS.jsx";
import { Card } from "./InfoClientCSS.jsx";

export default function CardInfoClient({ children }) {
      return (
            <Card className="card shadow"
            >
                  {children}
            </Card>
      );
}

