import React, { useState } from "react";
import { IoMdTime } from "react-icons/io";
import { GiCalendar } from "react-icons/gi";
import ConversationAtraiterCard from "./ConversationAtraiterCard";
import { Editor } from "primereact/editor";
import senegal from "../../../image/senegal.png"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import DropdownCountry from "./DropdownCountry";
import COLORS from "utils/Variables/color";
import ModalTextPrep from "./ModalTextPrep";

function ConversationAtraiter() {
    const [text, setText] = useState("");
    const [visible, setVisible] = useState(false);

    return (
        <div className="px-4">
            <div className="card-header">A traiter / Conversation avec Rémi Mendy</div>
            <div className="card-body">
                <div className="head-body py-3">
                    <div className="row justify-content-between">
                        <div className="col-md-6 d-flex py-4">
                            <h5 className="">Enquête Prestay <span className="fw-bold"> Rémy Mendy</span></h5>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <div>
                                <span className="fw-bold fs-5">Pays: </span>
                                <img src={senegal} alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="row py-2 px-2">
                        <div className="col-md-2 d-flex align-items-center gap-1" style={styledRelationClientDetail.textConversation}>
                            <span><IoMdTime className="fs-4" /></span>
                            <p className="m-0">Il y'a un jour</p>
                        </div>
                        <div className="col-md-3 d-flex align-items-center gap-1 justify-content-center">
                            <span><GiCalendar className="fs-4" /></span>
                            <p className="m-0">Arrivé depuis 8h</p>
                        </div>
                        <div className="col-md-1">
                            <p className="m-0">Nuitée</p>
                            <span className="fw-bold">2</span>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center flex-column">
                            <p className="m-0">Arrivée</p>
                            <span className="fw-bold">14/03/2024</span>
                        </div>
                        <div className="col-md-2 justify-content-center flex-column">
                            <p className="m-0">Départ</p>
                            <span className="fw-bold">16/03/2024</span>
                        </div>
                        <div className="col-md-2 justify-content-center flex-column">
                            <p className="m-0">Chambre</p>
                            <span className="fw-bold">5A</span>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="col-md-5 py-2">
                        <span style={styledRelationClientDetail.borderTextConversation}>
                            <h5 className="">Informations obtenues</h5>
                        </span>
                    </div>
                    <div className="row pb-5 px-2">
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Type de client</p>
                            <span className="fw-bold">En famille</span>
                        </div>
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Type de voyage</p>
                            <span className="fw-bold">Loisir</span>
                        </div>
                        <div className="col-md-3" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Viens souvent à Dakar</p>
                            <span className="fw-bold">Non</span>
                        </div>
                        <div className="col-md-2" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Anniversaire</p>
                            <span className="fw-bold">04 Avril 1995</span>
                        </div>
                        <div className="col-md-3" style={styledRelationClientDetail.textConversation}>
                            <p className="m-0">Heure d'Arrivée estimée</p>
                            <span className="fw-bold">09:45</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 border-end">
                            <div className="col-md-9">
                                <h6 className="fs-5 pb-2"><span style={styledRelationClientDetail.borderTextConversation} className="pb-2">Demande du client</span>(3)</h6>
                            </div>
                            <h6>Make a wish</h6>
                            <p>Nous avons oublié de vous proposer quelque chose ? dites le nous!</p>
                            <div>
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                                <ConversationAtraiterCard sizeText={styledRelationClientDetail.textConversation} colorIcon={"#E76F51"}
                                    text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                                    date={"10-03-2024 / 08:45"} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-10">
                                <h5 className="pb-2" style={styledRelationClientDetail.borderTextConversation}>Traitement des demandes</h5>
                            </div>
                            <h5>À: Monsieur Rémy Mendy</h5>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="">Sujet:</p>
                                    <p className="fw-bold" style={styledRelationClientDetail.borderTextConversation}>Thank you for your answer</p>
                                </div>
                                <div>
                                    <p className="m-0">Langue:</p>
                                    <div className="pt-3" style={styledRelationClientDetail.borderTextConversation}>
                                        <DropdownCountry />
                                    </div>
                                </div>
                            </div>
                            <div className="card border-0">
                                <div className="d-flex justify-content-end">
                                    <div className="border-top border-start border-end p p-2 rounded-top"
                                        style={{ backgroundColor: `${COLORS.secondaryBackground}`, color: "#fff", cursor: "pointer" }} onClick={() => setVisible(true)}>Textes préparés</div>
                                </div>
                                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: "200px" }} />
                            </div>
                        </div>
                        <ModalTextPrep visible={visible} setVisible={setVisible} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConversationAtraiter
