import React from "react";
import Traiter from "components/Traiter/Traiter";
import CalendrierduClient from "./CalendrierduClient/CalendrierduClient";
import ServiceCommande from "./ServiceCommander/ServiceCommande";
import { styledRelationClientDetail } from "./RelationClientDetailPage/RelationClientDetailCss";
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import { Heading1 } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
const DashboardRelation = () => {
  return (
    <div className="container-fluid pt-5" style={styledRelationClientDetail.relationClient}>
      <Heading1>
        Tableau de bord relation client
      </Heading1>
      <ContainerDemandeClient className="container mt-5">
        <Traiter />
      </ContainerDemandeClient>
      <ContainerDemandeClient className="container mt-5">
        <CalendrierduClient />
      </ContainerDemandeClient>
      <ContainerDemandeClient className="container mt-5 mb-5">
        <ServiceCommande />
      </ContainerDemandeClient>
    </div>
  );
};

export default DashboardRelation;
