import React from "react"
import DrapeauSenegal from "image/DrapeauSenegal.jpg"
import InfoCard from "components/InfoCard/InfoCard"

function ATraiterCard({ func }) {
    return (
        <InfoCard padding="2px" name="Rémy Mendy" Pays="Senegal" countryImage={DrapeauSenegal} preStayDate="13/03/2024" />
    )
}

export default ATraiterCard