import React from "react";
import { MultiSelect } from "primereact/multiselect";

export default function SelectChambre({ chambres, setChambres, setNombreChambres, optionsChambres }) {
    const OptionsChambres = optionsChambres.map((chambreRecup, index) => ({
             name: chambreRecup.numero, id: chambreRecup.id
            }))
    const updateNombreChambres = (selectedChambres) => {
        setChambres(selectedChambres);
        setNombreChambres(selectedChambres.length);
    };
    return (
        <div className="card flex justify-content-center">
            <MultiSelect value={chambres} onChange={(e)=>updateNombreChambres(e.value)} options={OptionsChambres} optionLabel="name"
                placeholder="" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ height: "40px", outline: "none" }} />
        </div>
    );
}