export const PoductService = [
    {
        Header: "Prénom et nom",
        accessor: "prenom_nom"
    },
    {
        Header: "Adresse e-mail",
        accessor: "adresse_mail"
    },
    {
        Header: "Numéro de Téléphone",
        accessor: "numero_tel"
    },
    {
        Header: "Date début séjour",
        accessor: "date_debut_sejour"
    },
    {
        Header: "Date fin séjour",
        accessor: "date_fin_sejour"
    },
    {
        Header: "Adresse",
        accessor: "adresse"
    },
    {
        Header: "Status",
        accessor: "status"
    },
    {
        Header: "Action",
        accessor: "action"
    }
]