import React from "react";
import { Dialog } from "primereact/dialog";
import COLORS from "utils/Variables/color";

function ModalTextPrep({ visible, setVisible }) {
    return (
        <div className="card flex justify-content-center">
            <Dialog visible={visible} style={{ width: "30vw", minHeight: "100vh" }} onHide={() => setVisible(false)} dismissableMask={true} closable={false}>
                <div className="mb-2">
                    <h5 style={{ backgroundColor: `${COLORS.secondaryBackground}`, color: "#fff" }} className="p-2">Pre Stay</h5>
                    <div className="p-2">
                        <p>Message 1</p>
                        <p>Message 2</p>
                        <p>Message 3</p>
                        <p>Message 4</p>
                    </div>
                </div>
                <div className="mb-2">
                    <h5 style={{ backgroundColor: `${COLORS.secondaryBackground}`, color: "#fff" }} className="p-2">Conciergerie</h5>
                    <div className="p-2">
                        <p>Message 1</p>
                        <p>Message 2</p>
                        <p>Message 3</p>
                        <p>Message 4</p>
                    </div>
                </div>
                <div className="mb-2">
                    <h5 style={{ backgroundColor: `${COLORS.secondaryBackground}`, color: "#fff" }} className="p-2">In Stay</h5>
                    <div className="p-2">
                        <p>Message 1</p>
                        <p>Message 2</p>
                        <p>Message 3</p>
                        <p>Message 4</p>
                    </div>
                </div>
                <div className="mb-2">
                    <h5 style={{ backgroundColor: `${COLORS.secondaryBackground}`, color: "#fff" }} className="p-2">Post Stay</h5>
                    <div className="p-2">
                        <p>Message 1</p>
                        <p>Message 2</p>
                        <p>Message 3</p>
                        <p>Message 4</p>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ModalTextPrep