import React, { useState } from "react";
import "./Navbar.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import { FaRegBell } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { SearchInput } from "./Navbarcss";


const Navbar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [valueSearch, setValueSearch] = useState("")
  const [nomHotel, setNomHotel] = useState(" Dakar")

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  // const handleItemClick = (index) => {
  //   setActiveIndex(index === activeIndex ? null : index);
  // };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div
      className="navbar-container px-5 pt-2"
      style={{ border: "1px solid gray", backgroundColor: "white" }}
    >
      <div className="navbar">
        <NavLink
          to=""
          className={`nav-item ${activeIndex === 0 ? "active" : ""}`}
          onClick={() => handleItemClick(0)}
        >
          Tableau de bord
        </NavLink>
        <NavLink
          to="gestionaire-du-client"
          className={`nav-item ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => handleItemClick(1)}
        >
          Gestion client
        </NavLink>
        <NavLink
          to="statistique"
          className={`nav-item ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
        >
          Statistiques
        </NavLink>
        <NavLink
          to="plus"
          className={`nav-item ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleItemClick(3)}
        >
          Plus
        </NavLink>
      </div>
      <div className="right-items">
        {/* <input type="text" placeholder="Rechercher" className="search-bar" />
        <button className="logout-button">Déconnexion</button> */}
        {isDropdownVisible && (
          <div
            className="dropdown-menu show d-block d-lg-none"
            style={{ position: "absolute", top: "70px", right: "0" }}
          >
            <div className="d-flex">
              <span className="p-input-icon-left d-flex align-items-center w-100">
                <input
                  className="d-block bg-white w-100 border-2 rounded-pill border-0 searcher fs-5 ps-3"
                  placeholder="Recherche..."
                  type="text"
                  name="search"
                />
              </span>
            </div>
            <button
              onClick={handleLogout}
              className="btn d-flex align-items-center gap-2"
            >
              <FiLogOut className="fs-5" />
              <span>Déconnexion</span>
            </button>
          </div>
        )}
        <div
          className="d-flex"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="d-flex d-none d-lg-block">
            <span className="p-input-icon-left d-flex align-items-center w-64  w-100">
              <span className="position-absolute d-flex align-items-center ps-2">
                {/* <IoSearch className="fs-5 text-secondary-subtle" /> */}
              </span>
              {/* <input
                className="d-block bg-white w-100 border-2 rounded-pill outline-none searcher fs-5"
                placeholder="Recherche..."
                type="text"
                name="search"
                style={{ paddingLeft: "30px" }}
              /> */}
              <SearchInput value={valueSearch} onChange={(e) => setValueSearch(e.target.value)} placeholder="Recherche..." />
            </span>
          </div>
          <button className="btn">
            <FaRegBell className="fs-5" />
          </button>
          <button onClick={toggleDropdown} className="btn d-lg-none d-block">
            <IoMdArrowDropdown />
          </button>
          <div>
            {" "}
            <div>
              <div className="dropdown" style={{ fontFamily: "Roboto" }}>
                <p className="dropdown-toggle mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Azalaï Hôtel {nomHotel}
                </p>
                <div className="dropdown-menu dropdown-menu-light pt-3 mt-3" style={{ fontSize: "18px", width: "300px" }}>
                  <p onClick={() => setNomHotel(" Dakar")}><a className="dropdown-item" href="#">Azalaï Hôtel Dakar</a></p>
                  <p onClick={() => setNomHotel(" Abidjan")}><a className="dropdown-item" href="#">Azalaï Hôtel Abidjan</a></p>
                  <p onClick={() => setNomHotel(" Bamako")}><a className="dropdown-item" href="#">Azalaï Hôtel Bamako</a></p>
                  <p onClick={() => setNomHotel(" Nouakchott")}><a className="dropdown-item" href="#">Azalaï Hôtel Nouakchott</a></p>
                  <p onClick={() => setNomHotel(" Cotonou")}><a className="dropdown-item" href="#">Azalaï Hôtel Cotonou</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
