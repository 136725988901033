import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const connexionSchema = yup.object().shape({
    code: yup.string().required("Le code qu'on vous a envoyé est requis"),
    email: yup
      .string()
      .email("Email invalide!")
      .required("L'email est requis."),
    new_password_confirm: yup.string().required("Confirmer le mot de passe"),
    new_password: yup.string().required("Le mot de passe est requis."),
})

const ConnexionProviderResetPwd = ({ children }) => {
    const methods = useForm({
      values: {
        code: null,
        email: "",
        new_password_confirm: "",
        new_password: "",
      },
      defaultValues: {
        code: null,
        email: "",
        new_password_confirm: "",
        new_password: "",
      },
      resolver: yupResolver(connexionSchema),
    });
    // Enveloppe les composants enfants dans le contexte du formulaire (FormProvider).
    return <FormProvider {...methods}>{children}</FormProvider>;
  };
export default ConnexionProviderResetPwd;