// NavbarComponent.jsx
import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import hotels from "../../image/azalai.png";
// import globe from "../../image/globe.png";
import Ellipse from "../../image/Ellipse.png";
import NavbarItem from "components/NavbarcomponentItem";
import { ADMIN_PATH, LOGIN_PATH, MARKETING_PATH, RELATION_CLIENT_PATH } from "routes/navigation/navigationPaths";
import { MdLanguage } from "react-icons/md";
import DrapeauAllemagne from "image/DrapeauAllemagne.jpg"
import DrapeauAngleterre from "image/DrapeauAngleterre.jpg"
import DrapeauChine from "image/DrapeauChine.jpg"
import DrapeauEspagne from "image/DrapeauEspagne.png"
import DrapeauFrance from "image/DrapeauFrance.png"
import DrapeauPortugal from "image/DrapeauPortugal.png"
import { Img } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, selectFirstName, selectLastName } from "redux/features/userSliceWithTokenValidation";
import styled from "styled-components";


const Logo = styled.img`
width:25px;
height:25px;
`
const ParaLogo = styled.p`
color:white; 
margin-top: 15px;
margin-right: 25px;
font-size: 18px;
font-weight: 700; 
`

const NavbarComponent = ({ bgColor, navbarContainerStyles }) => {
  const navbarItems = [
    // { path: "/", label: "", icon: hotels },
    { path: RELATION_CLIENT_PATH, label: "Relation client" },
    { path: MARKETING_PATH, label: "Marketing" },
    { path: ADMIN_PATH, label: "Admin" },
    // Add more navbar items as needed
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const handleLogout = async () => {
    try {
      await dispatch(logout())
      navigate(LOGIN_PATH);
    } catch (error) {
      console.error("Error loggin out :", error);
    }
  };
  const [lang, setLang] = useState("FR")
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        height: 50,
        backgroundColor: bgColor ? bgColor : "#655573",
        ...navbarContainerStyles,
      }}
    >
      <div className="d-flex align-items-center mt-2">
        <div className="d-inline-flex align-items-center mb-2">
          <Logo src={hotels} alt="Hotels" className="me-2 ms-3" />
          <ParaLogo>{firstName} {lastName} Dakar</ParaLogo>
        </div>

        {navbarItems.map((item, index) => (
          <NavbarItem
            key={index}
            linkItem={item}
            activeLinkColor="white" // Customize active link color
            activeLinkBgColor="#8E715B" // Customize active link background color
            colorOnHover="white" // Customize link color on hover
            linkColor="white" // Customize default link color
          />
        ))}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Other elements on the right side */}
        <div className="dropdown" style={{ fontFamily: "Roboto" }}>
          <div className="" type="button" data-bs-toggle="dropdown" aria-expanded="false"
            style={{
              marginLeft: 10,
              marginRight: 15,
              color: "#FFF",
              cursor: "pointer",
              transition: "color 0.3s",
            }}
          >
            <MdLanguage /> {lang}
          </div>
          <div className="dropdown-menu dropdown-menu-light pt-3 ps-3 border-none" style={{ cursor: "pointer" }}>
            <p onClick={() => setLang("DE")}><Img src={DrapeauAllemagne} alt="" /> Allemand</p>
            <p onClick={() => setLang("ANG")}><Img src={DrapeauAngleterre} alt="" /> Anglais</p>
            <p onClick={() => setLang("CN")}><Img src={DrapeauChine} alt="" /> Chinois</p>
            <p onClick={() => setLang("ES")}><Img src={DrapeauEspagne} alt="" /> Espagnol</p>
            <p onClick={() => setLang("FR")}><Img src={DrapeauFrance} alt="" /> Français</p>
            <p onClick={() => setLang("PT")}><Img src={DrapeauPortugal} alt="" /> Portugais</p>
          </div>
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 15,
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
        >
          Vidéo tutoriels
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 15,
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
        >
          Support
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 15,
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
        >
          Store
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 10,
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
        >
          <img
            src={Ellipse}
            alt="Profile"
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
          />
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 15,
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
          }}
        >
          <FiLogOut className="fs-5" onClick={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
