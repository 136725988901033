import React, { useState } from "react"
import { Editor } from "primereact/editor";
import { DivSignePlus } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import { Button, Input, Label } from "./NouvelleEmailCss"
import Checkbox from "components/RelationClientDetail/InfoClient/Checkbox";
import { useNavigate } from "react-router-dom";
import { TEXTESPREPARES, ADMIN_PATH } from "routes/navigation/navigationPaths";

export default function NouvelleEmail() {
    const navigate = useNavigate()
    const switchEmail = () => {
        navigate(`/${ADMIN_PATH}/${TEXTESPREPARES}`);
    }
    const [text, setText] = useState("");
    const renderHeader = () => {
        return (
            <div className="ql-toolbar ql-snow d-flex">
                <span className="ql-formats">
                    <button className="ql-bold" aria-label="Bold"></button>
                    <button className="ql-italic" aria-label="Italic"></button>
                    <button className="ql-underline" aria-label="Underline"></button>
                    <select className="ql-color" aria-label="Text Color">
                        <option value="red"></option>
                        <option value="green"></option>
                        <option value="blue"></option>
                        <option style={{ border: "1px solid #ccc" }} value="white"></option>
                        <option value="orange"></option>
                        <option value="purple"></option>
                        <option value="black"></option>
                    </select>
                    <select className="ql-background" aria-label="Background Color">
                        <option value="red"></option>
                        <option value="green"></option>
                        <option value="blue"></option>
                        <option value="orange"></option>
                        <option style={{ border: "1px solid #ccc" }} value="white"></option>
                        <option value="purple"></option>
                        <option value="black"></option>
                    </select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-align" value="" aria-label="Align Left"></button>
                        <button className="ql-align" value="center" aria-label="Align Center"></button>
                        <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
                        <button className="ql-list" value="bullet" aria-label="Unordered List"></button>
                        {/* <button className="ql-paragraph" value="p" aria-label="Paragraph"></button> */}
                    </span>
                    <span className="ql-formats">
                        <button className="ql-link" aria-label="Insert Link"></button>
                        <button className="ql-image" aria-label="Insert Image"></button>
                        {/* </button><button className="ql-emoji" aria-label="Insert Emoji"></button> */}
                    </span>
                    <div className="d-flex">
                        <DivSignePlus className="mt-1 me-2">+</DivSignePlus>
                        <Label style={{ color: "#FFC857" }}>Ajouter une shortcode</Label>
                    </div>
            </div>
        );
    };

    const header = renderHeader();

    const optionsMode = [
      { value: "allowed", label: "Utilisé les chortcode de l'hotels" },
      { value: "notAllowed", label: "Ne pas utilisé les chortcode de l'hotel" },
    ]
    const optionsShortcode = [
        { value: "allowed", label: "Wysiwyg" },
        { value: "notAllowed", label: "Html" },
      ]

  return (
        <div className="pt-4">
        <form action="" className="d-flex flex-column" style={{ width: "100%" }}>
            <Label htmlFor="">Nom de l'E-mail</Label>
            <Input className="py-2 my-3" type="text" placeholder="Nom de l'E-mail" />
            <Label>Mode</Label>
            <Checkbox flexDirection="column" options={optionsMode} my="my-3"/>
            <Label htmlFor="">Sujet de l'E-mail</Label>
            <Input className="py-2 my-3" type="text" placeholder="Sujet de l'E-mail" />
            <div className="d-flex">
                <DivSignePlus className="mt-1 me-2">+</DivSignePlus>
                <Label style={{ color: "#FFC857" }}>Ajouter une shortcode</Label>
            </div>
            <Checkbox flexDirection="row" options={optionsShortcode} my="my-3" me="me-5"/>
            <Label htmlFor="">Description de la session</Label>
            <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} headerTemplate={header} style={{ height: "150px" }} />
        </form>
        <div className="d-flex mt-5">
                <Button background="#FFC857" color="#000" borderColor="none" onClick={switchEmail}>Sauvegarder et quitter</Button>
                <Button background="#FFF" color="#FFC857" borderColor="1px solid #FFC857">Sauvegarder</Button>
                <Button background="#FFF" color="#FFC857" borderColor="1px solid #FFC857" onClick={switchEmail}>Annuler</Button>
        </div>
        </div>
  )
}
