import React from "react"
import { RxPerson } from "react-icons/rx"

function ConversationCard({ text, date, id, nameHotel, client }) {
  return (
    <div className={`d-flex ${!client && "justify-content-end"}`}>
      {client && <div className="col-1 d-flex justify-content-center py-2">
        <div className="text-white bg-dark rounded-circle fs-4 px-3 d-flex jutify-content-center align-items-center"
          style={{ height: "50px", width: "50px" }}><RxPerson /></div>
      </div>}
      <div className="col-md-9">
        <div className="card-body row rounded" style={{
          marginTop: "5px",
          boxShadow: "1px 1px 3px gray",
          margin: "15px",
          padding: "5px",
          backgroundColor: `${id === "messageId" ? "#F0F0F0" : "#fff"}`,
        }}>
          <p className="fs-5"><span className="border rounded">{nameHotel}</span> {text}</p>
          <p className="text-end">{date}</p>
        </div>
      </div>
    </div>
  )
}

export default ConversationCard