import React from "react";
import MenuClientDetail from "components/RelationClientDetail/MenuClientDetails/MenuClientDetail";
import { styledRelationClientDetail } from "./RelationClientDetailCss";
import CardLeft from "components/RelationClientDetail/InfoClient/CardLeft";
import CardRight from "components/RelationClientDetail/InfoClient/CardRight";
import "./Style.css"

const RelationClientDetail = () => {
  return (
    <div className="container-fluid py-5 relationClient mt-5" style={styledRelationClientDetail.relationClient}>
      <div className="row justify-content-around mt-3 px-3">
        <div className="col-12 col-md-3">
          <CardLeft />
        </div>
        <div className="col-12 col-md-6 px-4">
          <MenuClientDetail />
        </div>
        <div className="col-12 col-md-3">
          <CardRight />
        </div>
      </div>
    </div>
  )
}

export default RelationClientDetail