import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ImageFond from "../../image/BgGroup.png";
import COLORS from "utils/Variables/color";

export const ContentTextPrep = styled.div`
    background-color: #655573;
    background-image: url(${ImageFond});
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100% !important;
    color: #FFFFFF !important;
`;

export const ContainerAdminTextPrep =styled.div`
    background: #FFF;
    border-radius: 14px;
    color: #000
`;

export const SidebarTextPrep = styled.div`
    background: #FFF;
`;

export const ContenuTextPrep = styled.div`
    background: #FFF;
`

export const SidebarItems = styled(NavLink)`
    border: 1px solid ${COLORS.primaryBorderColor};
    border-radius: 7px;
    font-family: Roboto !important;
    font-weight: 700;
    font-size: 16px !important;
    color: #000;
    text-decoration: none;
    transition: background-color 0.3s ease;
    &.active {
        background-color: ${COLORS.activeColor};
        color: "#fff"
    }
`;

export const AdminTableEmail = styled.div`
    border-radius: 7px;
    font-family: Roboto
`;

export const AdminTableEmailTh = styled.th`
    background-color: ${COLORS.tertiaryBackground} !important;
`;