import React from "react";
import { ConnexionBody } from "components/ConnexionForm/LoginPageStyle";
import { useResetPasswordMutation } from "services/auth-services/AuthService";
import { useFormContext } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "components/Boutton/CustomButton";
import hotels from "../../image/hotels.png";
import Swal from "sweetalert2";


function ExempleConnexionFormReset() {
    const { register, handleSubmit, formState, reset } = useFormContext();
    const navigate = useNavigate();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    const handleReset = async (data) => {
        const { data: userData, error } = await resetPassword(data);
        try {
            if (error) {
                Swal.fire({
                icon: "error",
                title: "Une erreur est survenue lors de la connexion.",
                showConfirmButton: false,
                timer: 1500,
                });
            } else {
                reset()
                navigate("/login")
                console.log(userData)
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <ConnexionBody className="d-flex justify-content-center align-items-center">
            <div className="containerrr">
                <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                    <div className="mb-1">  <img src={hotels} alt="Logo" /> </div>
                    <form className="auth-forms p-4" autoComplete="off" onSubmit={handleSubmit(handleReset)}>
                        <div className="d-flex flex-column gap-2 bg-white rounded" style={{ padding: "20px" }}>
                            <div style={{ fontSize: "16px", width: "270px" }}> <b> Veuillez réinitialiser votre mot de passe ici en récupérant le code envoyé dans votre mail</b></div>
                            <div>
                                <p style={{ width: "270px", fontSize: "14px", paddingTop: "20px" }}>Vérifier le code envoyé dans votre mail et saisir le nouveau mot de passe</p>
                            </div>
                            <div className="d-flex flex-column">
                                <input
                                    type="email"
                                    className="input-form border-2 border-bottom border-light-subtle"
                                    id="email"
                                    placeholder="Email"
                                    {...register("email", { required: true })}
                                />
                                {formState.errors && formState.errors.email && (
                                    <div
                                    className="alert alert-danger gfa-alert-danger"
                                    role="alert"
                                    >
                                    {formState.errors.email?.message}
                                    </div>
                                )}
                                <input
                                    type="number"
                                    className="input-form border-2 border-bottom border-light-subtle"
                                    id="code"
                                    placeholder="Code reçu"
                                    {...register("code", { required: true })}
                                />
                                {formState.errors && formState.errors.code && (
                                    <div
                                      className="alert alert-danger gfa-alert-danger"
                                      role="alert"
                                    >
                                      {formState.errors.code?.message}
                                    </div>
                                )}
                                <input
                                  type="password"
                                  className="input-form border-2 border-bottom border-light-subtle"
                                  id="new_password"
                                  placeholder="Nouveau mot de passe"
                                  {...register("new_password", { required: true })}
                                />
                                {formState.errors && formState.errors.new_passord && (
                                    <div
                                      className="alert alert-danger gfa-alert-danger"
                                      role="alert"
                                    >
                                      {formState.errors.new_password?.message}
                                    </div>
                                )}
                                <input
                                    type="password"
                                    className="input-form border-2 border-bottom border-light-subtle"
                                    id="confirmPassword"
                                    placeholder="Confirmer Mot de passe"
                                    {...register("new_password_confirm", { required: true })}
                                />
                                {formState.errors && formState.errors.new_password_confirm && (
                                    <div
                                      className="alert alert-danger gfa-alert-danger"
                                      role="alert"
                                    >
                                      {formState.errors.new_password_confirm?.message}
                                    </div>
                                )}
                            </div>
                            <div className="text-center">
                                <CustomButton
                                    type="submit"
                                    className="btn w-75 fw-bold"
                                    backgroundColor="#FFC857"
                                    color="#000000"
                                    border="none"
                                    fontSize="16px"
                                >
                                    <span style={{ color: "#000000" }}>
                                        {isLoading ? "Chargement..." : "Envoyer"}
                                    </span>
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                    <div className="text-center d-flex gap-1">
                        <p className="text-white"> Revenir à la</p>
                        <Link to="/login">
                            <span style={{ color: "#FFD964", fontSize: "16px", textDecoration: "none" }}>connexion</span>
                        </Link>
                    </div>
                </div>
            </div>
        </ConnexionBody>
    );
}

export default ExempleConnexionFormReset