import React, { useState } from "react";
import { useMemo } from "react";
// import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useWindowDimensions from "../hooks/useWindowsDimention";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const NavbarPaner = styled.div`
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: auto;
  z-index: 4;
`;
const BodyContainer = styled.div`
display: flex;
`;

const ContaintOutlet = styled.div`
  width: 100%;
  height: 100vh;
  // background-color: red;
  background-color: #F0F0F0;
  transition: padding 0.3s;
  overflow-x: hidden;
  z-index: 1;
  padding: ${({ openedMenu, minViewPort }) =>
    openedMenu ?
      " 0px 0 40px" :
      minViewPort ?
        "0px 10px 0 0px" :
        "40px 0px 0 0px"};
`;

const SpliTemplateScreen = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [minViewPort, setMinViewPort] = useState(false);

  const [navbar, outlet] = children;

  const { width } = useWindowDimensions();

  useMemo(() => {
    if (width <= 900) {
      setMinViewPort(true);
      setOpenedMenu(true);
    } else {
      setMinViewPort(false);
      setOpenedMenu(false);
    }
  }, [width]);

  return (
    <Container>
      <NavbarPaner>
        {navbar}
      </NavbarPaner>
      <BodyContainer>
        {/* <SidebarPaner openedMenu={openedMenu} ref={sidebarRef}>
          <MenuController onClick={handleResize}>
            {openedMenu ? (
              <AiOutlineArrowRight className="menu-controller-icon" />
            ) : (
              <AiOutlineArrowLeft className="menu-controller-icon" />
            )}
          </MenuController>
          {sidebar}
        </SidebarPaner> */}
        <ContaintOutlet openedMenu={openedMenu} minViewPort={minViewPort}>
          {outlet}
        </ContaintOutlet>
      </BodyContainer>
    </Container>
  );
};

export default SpliTemplateScreen;
