import React from "react";

const CustomButton = ({
    className, backgroundColor,
    color, border, padding, margin,
    fontSize, fontWeight, width, height, borderRadius, children }) => {
    return (
        <button
            className={className}
            style={{
                backgroundColor: backgroundColor,
                fontWeight: fontWeight,
                color: color,
                border: border,
                padding: padding,
                margin: margin,
                fontSize: fontSize,
                width: width,
                height: height,
                borderRadius: borderRadius
            }}
        >
            {children}
        </button>
    );
}

export default CustomButton;
