import React from "react"
import ConversationCard from "./ConversationCard";
// import { RxPerson } from "react-icons/rx";
import { FiSend } from "react-icons/fi";
import { BsChatRightText, BsPlusLg } from "react-icons/bs";
import { BsEmojiLaughing } from "react-icons/bs";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineEnvelope } from "react-icons/hi2";

function Conversation() {
    const render = () => {
        return (
            <div>
                <div>
                    <span>Messages</span>
                    <span><BsChatRightText /></span>
                </div>
                <div>
                    <span>WhatsApp</span>
                    <span><FaWhatsapp /></span>
                </div>
                <div>
                    <span>Email</span>
                    <span><HiOutlineEnvelope /></span>
                </div>
            </div>
        )
    }
    return (
        <div className="conversation">
            <p className="px-3">Conversation avec Rémy/</p>
            <div className="px-3">
                <div>
                    <ConversationCard text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                        date={"10-03-2024 / 08:45"} client={true} />
                    <ConversationCard
                        text={"Pas de problème ! Nous avons réservé une table pour vous au restaurant.Nous vous attendrons à l'heure convenue."}
                        date={"10-03-2024 / 08:45"}
                        nameHotel={"Azalai Hôtel Dakar"}
                        id={"messageId"}
                    />
                    <ConversationCard text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                        date={"10-03-2024 / 08:45"} client={true} />
                </div>
            </div>
            <div className="row m-0 py-3 position-static top-100" style={styledRelationClientDetail.messageClient}>
                <div className="col-2 d-flex justify-content-around align-items-center">
                    <BsEmojiLaughing className="fs-2" />
                    <BsPlusLg className="fs-5" />
                </div>
                <div className="col-9">
                    <input type="text" className="form-control shadow-none py-3" placeholder="Message" />
                </div>
                <div className="col-1 d-flex align-items-center">
                    <FiSend className="fs-3" onClick={render} />
                </div>
            </div>
        </div>
    )
}

export default Conversation
