import ImageFond from "image/BgGroup.png"
import styled from "styled-components";
import FONTS from "utils/Variables/police";

const styledRelationClientDetail = {
    relationClient: {
        backgroundColor: "#655573",
        backgroundImage: `url("${ImageFond}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
        fontFamily: `${FONTS.Roboto} !important`,
        height: "100vh !important",
    },
    contentTabs: {
        backgroundColor: "#FFF",
        height: "100%",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 300px)",
    },
    detailClientTabsLi: {
        cursor: "pointer",
    },
    activetab: {
        color: "#fff"
    },
    messageClient: {
        background: "#F0F0F0",
    },
    statusHistorique: {
        backgroundColor: "#FFF2CF",
        color: "#FFB800"
    },
    arrowLeft: {
        position: "absolute",
        top: "50%",
        left: "-10px",
        transform: "translateY(-50%)",
        borderTop: "10px solid transparent",
        borderBottom: "10px solid transparent",
        borderRight: "10px solid #fff",
    },
    textConversation: {
        fontSize: "12px !important",
        margin: "0",
        padding: "0"
    },
    borderTextConversation: {
        borderBottom: "2px solid #655573",
        display: "inline-block",
    }
}

export { styledRelationClientDetail }

export const LinkBreadCrumb = styled.a`
    color: #000;
`;