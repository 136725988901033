import Navbar from "components/HeaderListe/Navbar";
import BreadCrumb from "components/RelationClientDetail/BreadCrumb";
import { DivBreadCrumb } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import React from "react"
import { Outlet } from "react-router-dom";

const Admin = () => {
  const breadcrumbLinks = [
    { text: "Accueil", url: "#" }
  ]
  return (
    <div className="d-flex flex-column">
      <Navbar />
      <div style={{ marginBottom: "50px" }}>
        <DivBreadCrumb style={{ marginTop: "72px" }}>
          <BreadCrumb links={breadcrumbLinks} />
        </DivBreadCrumb>
      </div>
      <Outlet />
    </div>
  );
}

export default Admin
