/*
|--------------------------------------------------------------------------
! All Your Application Navigations Paths Will Be Here
|--------------------------------------------------------------------------
*/

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "login";
export const REGISTER_PATH = "signup";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/auth/resetPassword";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "acceuil";
export const PRODUCTS_PATH = "products";
export const RELATION_CLIENT_PATH = "relationClient";
export const MARKETING_PATH = "marketing";
export const ADMIN_PATH = "admin";
export const DASHBOARD_ADMIN_PATH = "dashboard-admin";
export const TEXTESPREPARES = "textes-prepares";
export const DASHBOARD_RELATION_PATH = "dashboard-du-client";
export const ELEMENTS_TRAITES_PATH = "dashboard-du-client/elements-traites";
export const DETAIL_CLIENT_PATH = "detail-client";
export const GESTION_PATH = "gestionaire-du-client";
export const STATISTIQUE = "statistique";
export const PLUS = "plus";
export const DETAIL_CLIENT = "dashboard-du-client/detail-client"
export const TEXTESPREPARES_MESSAGES = "messages"
export const TEXTESPREPARES_MAIL = "email"
export const TEXTESPREPARES_ALL = "all"
export const TEXTESPREPARES_FORMULAIRE = "formulaire"
export const TEXTESPREPARES_NEW = "new-create"
export const ELEMENT_TRAITE = "dashboard-du-client/elements-traites"
export const DEMANDE_CLIENT_PATH = "demande-client";
export const GESTION_VOYAGEUR_PATH = "gestion-voyageur";
export const NOUVEAU_VOYAGEUR_PATH = "gestion-voyageur/nouvau-voyageur";
export const GESTION_RESERVATION_PATH = "gestion-reservation";
export const NOUVELLE_RESERVATION_PATH = "gestion-reservation/nouvelle-reservation";


/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";
