
import styled from "styled-components"
import BgGroup from "image/BgGroup.png"
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BgGroup});
  background-size: cover;
  background-position: center;
`;
export const Title = styled.h3`
  color: #FFFFFF;
  font-size: 26px;
  font-family: roboto;
  text-decoration: underline;
  text-align: center;
  padding-top: 90px;
  margin-bottom: 50px;
`;
export const Cadre = styled.div`
  background-color: white;
  color: black;
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 14px;
  height: calc(73vh - 120px)
`;
export const CadreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: 20px;
`;
export const Navig = styled.div`
  display: flex;
  gap: 10px;
`;
export const GrpBtn = styled.div`
  display: flex;
  gap: 10px;
`;
export const CadreHeader2 = styled.div`
  display: flex;
  margin: 10px;
  margin-bottom: 20px;
  gap: 15px;
`;
export const InputDate = styled.div`
  height: 30px;
  outline: none;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  font-size: 14px;
`;
export const CadreTab = styled.div`
  width: 95%;
  margin: auto;
  box-shadow: 1px 1px 3px gray;
  margin-top: 30px;
`
export const Icone = styled.div`
  padding-right: 7px;
  font-size: 14px;
  font-weight: bold;
`;
export const TextBut = styled.p`
  font-size: 14px;
  font-weight: bold;
  padding-top: 16px;
`;

export const Select = styled.select`
  height: 30px;
  outline: none;
  border-radius: 20px;
  border: 1px solid gray;
  font-size: 14px;
`;