import React from "react";
import { Select } from "./NouveauVoyageurCss";

function SelectComponent({ options, value, onChange }) {
  return (
    <Select value={value} onChange={onChange}>
      <optgroup>
        <option value="" hidden></option>
      </optgroup>
      {options.map((option) => (
        <option key={option.value} value={option.value} className="pe-3">{option.text}</option>
      ))}
    </Select>
  );
}

export default SelectComponent;
