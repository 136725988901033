import React from "react";
import ReserveAndTotalCard from "./ReserveAndTotalCard";
import Onglets from "./Onglets";

function MenuClientDetail() {
  return (
    <div>
      <div className="row">
        <div className="d-flex p-0 gap-3">
          <ReserveAndTotalCard number={"04"} title={"Réservations"} />
          <ReserveAndTotalCard number={"325.000 FXOF"} title={"Revenu total"} />
        </div>
      </div>
      <div className="row">
        <Onglets />
      </div>
    </div>
  )
}

export default MenuClientDetail