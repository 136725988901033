import React from "react"
import ConversationCard from "components/RelationClientDetail/MenuClientDetails/ConversationCard"
import { Heading1, Heading5 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss"
import ConversationAtraiterCard from "components/RelationClientDetail/MenuClientDetails/ConversationAtraiterCard"


export default function DemandeClient() {
  return (
    <div className="container-fluid pt-5" style={styledRelationClientDetail.relationClient}>
        <Heading1
        style={{
            color: "#FFF",
            textDecoration: "underline",
            textAlign: "center",
            paddingTop: "50px",
            fontWeight: "400"
        }}
        >
      Eléments déja traités
    </Heading1>
        <ContainerDemandeClient className="container mt-5 mb-5">
            <div className="row shadow p-3 mb-3 d-flex flex-row justify-content-center">
                <Heading5>A traiter</Heading5>
            </div>
            <div className="row p-5">
                <div className="col-12 col-md-6" style={{ borderRight: "1px solid #E0E0E0" }}>
                <Heading1 style={{ color: "#6C5B7B", textDecoration: "underline" }}>Demande du client</Heading1>
                    <div className="row d-flex align-items-center">
                        <ConversationAtraiterCard sizeText={{ FontSize: "18px" }} colorIcon={"#E0E0E0"}
                            text={"Je voudrais réserver une table pour ce soir au restaurant de l'hôtel."}
                            date={"10-03-2024 / 08:45"} />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <Heading1 style={{ color: "#6C5B7B", textDecoration: "underline" }}>Traitement de la demande</Heading1>
                    <ConversationCard
                    text={"Pas de problème ! Nous avons réservé une table pour vous au restaurant.Nous vous attendrons à l'heure convenue."}
                        date={"10-03-2024 / 08:45"}
                        nameHotel={"<...>Azalai Hôtel Dakar"}
                        id={"messageId"}
                        />
                </div>
            </div>
        </ContainerDemandeClient>
    </div>
  )
}
