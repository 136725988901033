import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "redux/features/userSliceWithTokenValidation";
import api from "routes/api/global-vars";
import { voyageurEndpoint } from "routes/api/endpoints"

export const voyageurApi = createApi({
    reducerPath: "voyageurApi",
    baseQuery: fetchBaseQuery({
        baseUrl: api,
        prepareHeaders: (headers, { getState }) => {
            const token = selectToken(getState());
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        addNewVoyageur: builder.mutation({
            query: (voyageurData) => ({
                url: voyageurEndpoint.addNewVoyageur(),
                method: "POST",
                body: voyageurData,
            }),
        }),
        getVoyageurs: builder.query({
            query: (userId) => ({
                url: voyageurEndpoint.addNewVoyageur(),
                method: "GET",
                params: { user: userId },
            }),
        }),
    }),
});

export const { useAddNewVoyageurMutation, useGetVoyageursQuery } = voyageurApi;

export default voyageurApi;
