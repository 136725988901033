import React, { useState } from "react"
import { Heading1 } from "components/RelationClientDetail/InfoClient/InfoClientCSS"
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss"
import { Cadre, CadreHeader, CadreHeader2, CadreTab, GrpBtn, Icone, InputDate, Navig, Select, TextBut } from "../GestionVoyageur/GestionVoyageurCss";
import CustomButton from "components/Boutton/CustomButton";
import "../../../RelationClient/DashboardRelationClient/RelationClientDetailPage/Style.css"
import { HiOutlineArrowPath } from "react-icons/hi2";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Calendar } from "primereact/calendar";
import { SearchInput } from "components/HeaderListe/Navbarcss";
import { Link } from "react-router-dom";
import ImportModal from "../GestionVoyageur/ImportModal";
import { useSelector } from "react-redux";
import { useGetReservationsQuery } from "services/ReservationService";
import { IoEyeSharp } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function GestionReservation() {
    const origineOptions = ["Manuel", "PMS"];
    const statusOptions = ["En cours", "Terminée", "Annulée"];
    const [date, setDate] = useState(null);
    const [valueSearch, setValueSearch] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
      setModalIsOpen(true);
    };

    const closeModal = () => {
      setModalIsOpen(false);
    };
    // Id hotelier connecté
    const userId = useSelector((state) => state.auth.user_id);
    const { data: reservationsData } = useGetReservationsQuery( userId )
    const reservations = reservationsData?.data || [];
    console.log({ reservations });

    return (
        <div className="container-fluid pt-5" style={styledRelationClientDetail.relationClient}>
            <Heading1
            style={{
                color: "#FFF",
                textDecoration: "underline",
                textAlign: "center",
                paddingTop: "50px",
                fontWeight: "400"
            }}
            >
            Gestion des réservations
            </Heading1>
            <Cadre className="py-4">
                <CadreHeader>
                    <Navig>
                    <p className="text-primary">Admin</p>
                    <p>/</p>
                    <p>Voyageurs</p>
                    </Navig>
                    <SearchInput value={valueSearch} onChange={(e) => setValueSearch(e.target.value)} placeholder="Rechercher un voyageur" />
                    <GrpBtn>
                    <Link >
                        <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                        <Icone> <HiOutlineArrowPath /> </Icone>
                        <TextBut className="d-flex justify-content-center align-items-center">Synchronisation</TextBut>
                        </CustomButton>
                    </Link>
                    <Link onClick={openModal}>
                        <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                        <Icone> <MdOutlineFileDownload /> </Icone>
                        <TextBut>Importer Excel/CSV</TextBut>
                        </CustomButton>
                    </Link>
                    <Link to="nouvelle-reservation">
                        <CustomButton className="d-flex justify-content-center align-items-center" width="180px" height="35px" borderRadius="7px" border="none" backgroundColor="#FFC857">
                        <Icone> <FaPlus /> </Icone>
                        <TextBut>Nouvelle réservation</TextBut>
                        </CustomButton>
                    </Link>
                    </GrpBtn>
                </CadreHeader>
                <CadreHeader2 className="row ms-3">
                    <div style={{ fontWeight: "500" }} className="col-1">Filtrer par</div>
                    <InputDate className="col-2">
                    <Calendar style={{ fontSize: "12px" }} id="buttondisplay" placeholder="Date début de séjour" value={date} onChange={(e) => setDate(e.value)} showIcon />
                    </InputDate>
                    <InputDate className="col-2">
                    <Calendar style={{ fontSize: "12px" }} id="buttondisplay" placeholder="Date fin de séjour" value={date} onChange={(e) => setDate(e.value)} showIcon />
                    </InputDate>
                    <Select className="col-1">
                    <option value="">Adresse</option>
                    {origineOptions.map((adresse, index) => (
                        <option key={index} value={adresse}>{adresse}</option>
                    ))}
                    </Select>
                    <Select className="col-1 dropdown">
                    <option value="">Status</option>
                    {statusOptions.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                    ))}
                    </Select>
                </CadreHeader2>
                <CadreTab>
                <table className="table">
                    <thead className="table-light table">
                            <tr>
                                <th>Prénom et nom</th>
                                <th>N° Réservation</th>
                                <th>Date début séjour</th>
                                <th>Date fin séjour</th>
                                <th>Origine</th>
                                <th>Paiement</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                    </thead>
                    <tbody>
                        {reservations.map((reservation, index) => (
                            <tr key={index}>
                                <td>{reservation.voyageur_details.prenom} {reservation.voyageur_details.nom}</td>
                                <td>{reservation.id}</td>
                                <td>{reservation.date_debut_sejour}</td>
                                <td>{reservation.date_fin_sejour}</td>
                                <td>{reservation.origin}</td>
                                <td>{reservation.origin}</td>
                                <td>{reservation.statut_reservation}</td>
                                <td>
                                    <IoEyeSharp />
                                    <RiDeleteBin6Line/>
                                    <FaEdit/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </CadreTab>
            </Cadre>
            <ImportModal isOpen={modalIsOpen} onRequestClose={closeModal} />
        </div>
      )
}
