// NavbarItem.jsx
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

const LinkItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 13px;
  height: 52px;
  overflow: hidden;
  text-decoration: none;
  color: ${({ color }) => color};
  &:hover,
  &.active {
    color: ${({ coloronhover }) => coloronhover};
    background-color: ${({ activelinkbgcolor }) => activelinkbgcolor};
  }
  &.active {
    color: ${({ coloronactive }) => coloronactive};
  }
  // @media (max-width: 1024px) {
  //   margin-bottom: 0px;
  // }
`;
// const IconImage = styled.img`
//   width: 25px;
//   height: 25px;

//   /* Apply a filter to convert the image to grayscale and increase brightness */
//   filter: brightness(0) invert(1);

//   /* Use mix-blend-mode to blend the image with the background */
//   mix-blend-mode: screen;
// `;

const NavbarItem = ({ linkItem, activeLinkColor, activeLinkBgColor, colorOnHover, linkColor }) => {
  const { path, label } = linkItem;

  // Function to determine if the icon is a React component or an image URL
  // const renderIcon = (Icon) => {
  //   if (typeof Icon === "string") {
  //     // Use the IconImage styled component for images
  //     return <IconImage src={Icon} alt={label} style={{ width: "100px" }} />;
  //   } else if (Icon) {
  //     return <Icon size={25} className="icon" />;
  //   }
  //   return null;
  // };

  return (
    <LinkItem
      color={linkColor}
      coloronhover={colorOnHover}
      coloronactive={activeLinkColor}
      activelinkbgcolor={activeLinkBgColor}
      to={path}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "22px",
          marginTop: "0px"
        }}
        className="d-flex align-items-center mx-auto mx-lg-0"
      >

        <p
          style={{
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: 0,
          }}
          className="d-none d-lg-block"
        >
          {label}
        </p>
      </div>
    </LinkItem>
  );
};

NavbarItem.propTypes = {
  linkItem: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
  }).isRequired,
  activeLinkColor: PropTypes.string.isRequired,
  activeLinkBgColor: PropTypes.string.isRequired,
  colorOnHover: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default NavbarItem;
