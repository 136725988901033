import React from "react";
import HistoriqueCard from "./HistoriqueCard";

function HistoriqClient() {
  return (
    <div className="row p-0 m-0">
      <HistoriqueCard number={"0125463"} montant={"125.000"} debut={"07/01/2024 à 09h00"} fin={"25/01/2024 à 09h00"} />
      <HistoriqueCard number={"0125463"} montant={"666.666"} debut={"02/01/2024 à 09h00"} fin={"20/01/2024 à 10h00"} />
      <HistoriqueCard number={"0125463"} montant={"666.666"} debut={"10/01/2024 à 09h00"} fin={"30/01/2024 à 15h00"} />
      <HistoriqueCard number={"0125463"} montant={"125.000"} debut={"20/01/2024 à 09h00"} fin={"205/02/2024 à 22h00"} />
    </div>
  )
}

export default HistoriqClient