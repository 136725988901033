import React, { useState } from "react";
import ATraiter from "./ATraiter";
import Conversation from "./Conversation";
import HistoriqueReservation from "./HistoriqueReservation";
import Liens from "./Liens";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";

function Onglets() {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="pt-3 gap-2 px-0" style={{ height: "calc(73vh - 150px)" }}>
            <div className="border-bottom px-3">
                <ul className="detail-client-tabs d-flex gap-5 list-unstyled">
                    <li
                        className={`detail-client-tabs-item fw-bold ${activeTab === 0 && "active-tab"
                            }`}
                        style={{ ...styledRelationClientDetail.detailClientTabsLi, ...styledRelationClientDetail.activetab }}
                        onClick={() => setActiveTab(0)}
                    >
                        À traiter
                    </li>
                    <li
                        className={`detail-client-tabs-item fw-bold ${activeTab === 1 && "active-tab"
                            }`}
                        style={{ ...styledRelationClientDetail.detailClientTabsLi, ...styledRelationClientDetail.activetab }}
                        onClick={() => setActiveTab(1)}
                    >
                        Conversation
                    </li>
                    <li
                        className={`detail-client-tabs-item fw-bold ${activeTab === 2 && "active-tab"
                            }`}
                        style={{ ...styledRelationClientDetail.detailClientTabsLi, ...styledRelationClientDetail.activetab }}
                        onClick={() => setActiveTab(2)}
                    >
                        Historique des réservations
                    </li>
                    <li
                        className={`detail-client-tabs-item fw-bold ${activeTab === 3 && "active-tab"
                            }`}
                        style={{ ...styledRelationClientDetail.detailClientTabsLi, ...styledRelationClientDetail.activetab }}
                        onClick={() => setActiveTab(3)}
                    >
                        Liens
                    </li>
                </ul>
            </div>
            <div className="contentTabs pt-3 rounded mt-3 px-0 border"
                style={{ ...styledRelationClientDetail.contentTabs }}>
                {activeTab === 0 && <ATraiter />}
                {activeTab === 1 && <Conversation />}
                {activeTab === 2 && <HistoriqueReservation />}
                {activeTab === 3 && <Liens />}
            </div>
        </div>
    )
}

export default Onglets