import React from "react"
import styled from "styled-components"

// Définir un composant stylisé pour la carte
const MyCard = styled.div`
    width: 325px;
    margin:15px;
    padding:0;
    border-radius:7px;
`

const ParaDesc = styled.p`
font-size:12px;
color: #667085;
`
const TitleCard = styled.h5`
text-direction:none;
color:#000000
`

const ImageContain = styled.div`
width:80px;
height:80px;
`

const CardContainAdmin = ({ icon, title, description, button }) => {
    return (
        <MyCard className="container mt-2 d-flex flex-column align-center text-center justify-around shadow bg-white">
            {icon && <ImageContain className=" mx-auto my-3 ">{icon}</ImageContain>}
            {title && <TitleCard className="my-2">{title}</TitleCard>}
            {description && <ParaDesc className="my-2 px-3">{description}</ParaDesc>}
            <hr />
            {button && <div className="my-3">{button}</div>}
        </MyCard>
    )
}

export default CardContainAdmin
