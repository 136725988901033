import React from "react";
import entypo from "../../image/entypo.png";
import sender from "../../image/sender.png";
import concierge from "../../image/concierge.png";
import happy from "../../image/happy.png";
import ok from "../../image/ok.png";
import ph_star from "../../image/ph_star.png";
import InfoCard from "components/InfoCard/InfoCard";
import { Link } from "react-router-dom";
import CardHeader from "./CardHeader";
import { CardBody, CardColumn, OkIconContainer, PreTacheText } from "./Prestay";
import styled from "styled-components";
import CustomButton from "components/Boutton/CustomButton";
import { DETAIL_CLIENT, RELATION_CLIENT_PATH, ELEMENT_TRAITE } from "routes/navigation/navigationPaths";
import { BsListCheck } from "react-icons/bs";
import DrapeauSenegal from "image/DrapeauSenegal.jpg"
import DrapeauFrance from "image/DrapeauFrance.png"

export const PsContain = styled.div`
  margin-bottom: 50px;
  font-family: Roboto;
`


const Traiter = () => {
  return (
    <div className="row card d-flex flex-row justify-content-center" style={{ border: "none" }}>
      <div className="card-header" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="row">
          <div
            className="col-md-6 d-flex align-items-center">
            <BsListCheck /> A traiter
          </div>
          <div
            className="col-md-6 d-flex justify-content-end">
            <Link to={`/${RELATION_CLIENT_PATH}/${ELEMENT_TRAITE}`} >
              <CustomButton
                className="btn news"
                backgroundColor="#FFC857"
                padding="10px, 30px, 10px, 30px"
                color="black"
              >Élément traité</CustomButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <CustomButton
          className="btn btn-primary"
          backgroundColor="#00FF92"
          color="white"
          border="none"
          padding="10px, 30px, 10px, 30px"
          margin="0 0 0 15px"
        >
          <img src={entypo} /> Nouveautés
        </CustomButton>
        <PsContain>
          <CardHeader image={sender} text="Pre-Stay" />
          <Link to={`${DETAIL_CLIENT}`}>
            <InfoCard padding="9px" size="15px" name="Rémy Mendy" Pays="Senegal" countryImage={DrapeauSenegal} preStayDate="13/03/2024" />
          </Link>
          <Link to="#">
            <InfoCard padding="9px" size="15px" name="Kilyan Mbappé" Pays="France" countryImage={DrapeauFrance} preStayDate="12/03/2024" />
          </Link>
        </PsContain>
        <PsContain>
          <CardHeader image={concierge} text="Conciergerie" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>
        <PsContain>
          <CardHeader image={happy} text="In-Stay" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>
        <PsContain>
          <CardHeader image={ph_star} text="Post-Stay" />
          <CardBody>
            <CardColumn>
              <div className="d-flex align-center">
                <OkIconContainer>
                  <img src={ok} />
                </OkIconContainer>
                <PreTacheText text="Aucune tâche restante" />
              </div>
            </CardColumn>
          </CardBody>
        </PsContain>
      </div>
    </div>
  );
};

export default Traiter;